import React from 'react';
import { create } from '../services/api';
import { auth } from '../services/firebase';

export const ApiContext = React.createContext({ api: null })

export function ApiProvider({ children }) {
  const [api, setApi] = React.useState(undefined)

  async function onUserUpdated(newUser) {
    if (newUser) {
      const tokenInfo = await newUser.getIdToken()
      setApi(create(tokenInfo))
    } else {
      setApi(null)
    }
  }

  React.useEffect(() => void auth.onAuthStateChanged(onUserUpdated), [])

  return (
    <ApiContext.Provider value={api}>
      { children }
    </ApiContext.Provider>
  );
}
