import React from 'react';
import { ApiContext } from '../../../../providers/ApiProvider';
import { NotificationContext } from '../../../../providers/NotificationProvider';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';

import CommonStyles from '../../../CommonStyles.module.scss';
import { Dialog } from 'primereact/dialog';
import GenericMaintenanceScreen from './GenericMaintanceScreen';
import FloatLabel from '../../../utils/FloatLabel';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import { InputNumber } from 'primereact/inputnumber';
import TownSelector from '../../../utils/selectors/TownSelector';
import FinalDestinationSelector
  from '../../../utils/selectors/FinalDestinationSelector';
import PortSelector from '../../../utils/selectors/PortSelector';
import { ToggleButton } from 'primereact/togglebutton';

function EditDialog({ id, onHide, visible, notify }) {
  const api = React.useContext(ApiContext)
  const notification = React.useContext(NotificationContext)
  const errorHandler = useErrorHandler('Something went wrong on managing the data')

  const [loading, setLoading] = React.useState(false)

  const [isFromPort, setIsFromPort] = React.useState(false)

  const [fromPort, setFromPort] = React.useState()
  const [fromPlaceOfBlending, setFromPlaceOfBlending] = React.useState()
  const [to, setTo] = React.useState()
  const [price, setPrice] = React.useState(0)

  React.useEffect(() => {
    if (isFromPort) {
      setFromPlaceOfBlending(undefined)
    } else {
      setFromPort(undefined)
    }
  }, [isFromPort])

  React.useEffect(refresh, [id])

  function refresh() {
    setLoading(true)
    if (id) {
      api.getLandTransport(id)
        .then(({ data }) => {
          setIsFromPort(!!data.fromPort)
          if (data.fromPort) {
            setFromPort(data.fromPort.id)
          } else {
            setFromPlaceOfBlending(data.fromPlaceOfBlending)
          }
          setTo(data.to.id)
          setPrice(data.price)
          setLoading(false)
        }).catch(errorHandler)
    } else {
      setIsFromPort(false)
      setFromPort(undefined)
      setFromPlaceOfBlending(undefined)
      setTo(undefined)
      setPrice(0)
      setLoading(false)
    }
  }

  function finish() {
    notification.sendSuccess('Charge saved successfully')
    onHide()
    notify()
  }

  function send() {
    if (to && (fromPort || fromPlaceOfBlending)) {
      const payload = { to, fromPort, fromPlaceOfBlending, price }
      setLoading(true)
      if (id) {
        api.editLandTransport(id, payload).then(finish).catch(errorHandler)
      } else {
        api.addLandTransport(payload).then(finish).catch(errorHandler)
      }
    }
  }

  function askDelete() {
    if (window.confirm(`Are you sure you want to delete?\nThis is an one-way action!`)) {
      api.deleteLandTransport(id).then(finish).catch(useErrorHandler)
    }
  }

  const footer = <>
    { id && !loading && <Button label="Delete" icon="fa fa-trash-alt" className="p-button-danger" onClick={askDelete}/> }
    { !loading && <Button label="Save" icon="fa fa-save" onClick={send}/> }
    <Button label="Cancel" className="p-button-secondary" onClick={onHide}/>
  </>

  const body = <>
    <div style={{ marginBottom: 8 }}>
      <ToggleButton
        id="isFromPort"
        onLabel="From Port"
        offLabel="From Place of Blending"
        checked={isFromPort}
        onChange={e => setIsFromPort(e.value)}
        style={{ width: '100%' }}
      />
    </div>
    <div className="p-grid">
      <div className="p-col">
        <div>
          <label htmlFor="from">From:</label><br/>
          {
            isFromPort ? (
              <PortSelector id="from" value={fromPort} onChange={setFromPort} allowNone={false}/>
            ) : (
              <TownSelector id="from" value={fromPlaceOfBlending} onChange={setFromPlaceOfBlending}/>
            )
          }
        </div>
      </div>
      <div className="p-col">
        <label htmlFor="to">To:</label><br/>
        <FinalDestinationSelector id="to" value={to} onChange={setTo}/>
      </div>
    </div>
    <div className="p-grid">
      <div className="p-col">
        <FloatLabel id="price" label="Price">
          <InputNumber
            inputId="price"
            mode="currency"
            currency="USD"
            value={price}
            onValueChange={e => setPrice(e.value)}
          />
        </FloatLabel>
      </div>
    </div>
  </>

  return (
    <Dialog
      onHide={onHide}
      visible={visible}
      header={id ? 'Editing' : 'Creating'}
      footer={footer}
    >
      { loading ? <ProgressSpinner className={CommonStyles.CenterSpinner}/> : body }
    </Dialog>
  )
}

export default function LandTransportMaintenance() {

  const api = React.useContext(ApiContext)

  const dialog = ({ id, onHide, visible, notify }) => (
    <EditDialog id={id} onHide={onHide} visible={visible} notify={notify} />
  )

  return (
    <GenericMaintenanceScreen
      dataProvider={api.getLandTransports}
      renderEditDialog={dialog}
    >
      <Column field="id" header="Id" sortable/>
      <Column field="price" header="Price" sortable/>
      <Column
        field="fromPlaceOfBlending"
        header="From Place Of Blending"
        body={({ fromPlaceOfBlending: from }) => !from ? '' : `${from.name} (${from.country?.alphaCode})`}
        sortable
      />
      <Column
        field="fromPort"
        header="From Port"
        body={({ fromPort: from }) => !from ? '' : `${from.name} (${from.country?.alphaCode})`}
        sortable
      />
      <Column
        field="to"
        header="To"
        body={(it) => `${it?.to?.town?.name} (${it?.to?.town?.country?.alphaCode})`}
        sortable
      />
    </GenericMaintenanceScreen>
  )
}
