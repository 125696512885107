export function orGet(value) {
  return typeof value === 'number' ? value : typeof value?.get === 'function' ? value.get() : undefined
}

const percentKeys = [
  'fobPriceAtOrigin',
  'seaFreight',
  'seaFreightInsurrance',
  'cifPriceDestination',
  'chargesAndFeesAtPort',
  'portForwarderFees',
  'demurrage',
  'customLevy',
  'ecowasAu',
  'statisticLevy',
  'solidarityLevy',
  'ccvrLevy',
  'eximLevy',
  'pricesExport',
  'garantyFound',
  'transportToInland',
  'otherSmallTransitFees',
  'portToWarehouse',
  'priceDeliveredToBlendingFactory'
]

export const sumKeys = [
  'fobPriceAtOrigin',
  'seaFreight',
  'seaFreightInsurrance',
  'cifPriceDestination',
  'chargesAndFeesAtPort',
  'portForwarderFees',
  'demurrage',
  'customLevy',
  'ecowasAu',
  'statisticLevy',
  'solidarityLevy',
  'ccvrLevy',
  'eximLevy',
  'pricesExport',
  'garantyFound',
  'transportToInland',
  'otherSmallTransitFees',
  'portToWarehouse',
  'priceDeliveredToBlendingFactory',
  'costIngredients',
]

export function sumResults(...resultsArray) {

  return resultsArray.reduce((buff, curr) => {
    const sumValues = Object.fromEntries(
     sumKeys.map(k => [k, orGet(buff[k]) + orGet(curr[k])])
    )

    return {
      ...buff,
      ...sumValues
    }
  })
}

export function sumResultsApplyingPercentage(results) {
  const a = results.map((result) => {
    const { product } = result;
    return Object.fromEntries(
      Object.entries(result)
        .map(([k, v]) => [k, percentKeys.includes(k) ? orGet(v) * (product.percentage / 100) : v])
    )
  })

  return sumResults(...a);
}
