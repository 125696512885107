import React from 'react';
import { useLocalFormulaResults } from '../../../../hooks';
import { ProgressSpinner } from 'primereact/progressspinner';

import { Category, ResultsTable } from '../../../ResultsTable';
import ResultsFilterSelector
  from '../../../utils/selectors/ResultsFilterSelector';
import Styles from './index.module.scss';
import { Checkbox } from 'primereact/checkbox';
import CurrencyEditor from '../../../utils/CurrencyEditor';
import { useDeepCompareMemoized } from '../../../../utils';
import {
  sumKeys,
  sumResultsApplyingPercentage,
} from '../../../../calculation/results-utils';

import { useSelector } from 'react-redux';
import {
  UrlFinalDestinationSelector,
  useUrlFinalDestination,
} from '../../../utils/selectors/url/UrlFinalDestinationSelector';
import {
  UrlPlaceOfBlendingSelector,
  useUrlPlaceOfBlending,
} from '../../../utils/selectors/url/UrlPlaceOfBlendingSelector';

export default function Results(){

  const formulaName = useSelector(state => state.formula.name)

  const destinationId = useUrlFinalDestination()
  const placeOfBlendingId = useUrlPlaceOfBlending()
  const [filter, setFilter] = React.useState(Object.values(Category))

  const [showDetails, setShowDetails] = React.useState(false)
  const [showOnlySummary, setShowOnlySummary] = React.useState(false)

  const results = useLocalFormulaResults(destinationId ? destinationId : undefined, placeOfBlendingId)

  const total = React.useMemo(() => {
    return results
      ? [sumResultsApplyingPercentage(results.results.map(it => it.MAX))]
      : []
  }, useDeepCompareMemoized([results]))

  function renderLoading() {
    return <ProgressSpinner/>
  }

  function renderBody() {
    return (
      <>
        <div className="p-grid" style={{ justifyContent: 'space-between' }}>
          <div className="p-col p-md-3">
            <label style={{ fontWeight: 'bold', fontSize: '.75rem'}} htmlFor="placeOfBlending">Place of Blending/Bagging:</label>
            <br/>
            <UrlPlaceOfBlendingSelector id="placeOfBlending" allowNone={false}/>
          </div>
          <div className="p-col p-md-3">
            <label style={{ fontWeight: 'bold', fontSize: '.75rem'}} htmlFor="destination">Final Destination:</label>
            <br/>
            <UrlFinalDestinationSelector id="destination"/>
          </div>
          <div className="p-col p-md-3">
            <label htmlFor="filter" style={{ fontWeight: 'bold', fontSize: '.75rem' }}>Filter:</label>
            <br/>
            <ResultsFilterSelector id="filter" value={filter} onChange={setFilter}/>
            <div className={Styles.FilterLabels}>
              <label style={{ marginRight: 8 }}>
                View Summary:
                <Checkbox checked={!showOnlySummary} onChange={e => setShowOnlySummary(!e.checked)}/>
              </label>

              <label>
                View Details:
                <Checkbox checked={showDetails} onChange={e => setShowDetails(e.checked)}/>
              </label>
            </div>
          </div>
          <div className="p-col p-md-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CurrencyEditor/>
          </div>
        </div>
        { placeOfBlendingId ? destinationId ? renderTable() : 'Please select a destination' : 'Please select a place of processing' }
      </>
    )
  }

  function renderTable() {
    return (
      <ResultsTable
        headersTitle="Product *"
        filter={filter}
        headers={[
          formulaName,
          ...(showOnlySummary ? results.results.map(it => `${it.MAX.product.percentage}% ${it.MAX.product.type.name}`) : []),
        ]}
        data={[
          ...total,
          ...(
            showOnlySummary
              ? results.results.map(it => it.MAX)
                .map(result => Object.fromEntries(
                  Object.entries(result)
                    .filter(([k]) => sumKeys.includes(k))
                  )
                )
              : []
          )
        ]}
        showDetails={showDetails}
      />
    )
  }

  return !results && destinationId ? renderLoading() : renderBody()
}
