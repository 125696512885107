import React from 'react';

import { Card } from 'primereact/card';

import { auth } from '../../../services/firebase';

import Styles from './EmailNotVerified.module.scss';

export default function EmailNotVerified() {

  const signOut = () => auth.signOut()

  return (
    <Card
      className={Styles.EmailNotVerified}
      title="Your e-mail is not verified yet"
      subTitle={`We sent a verification link to your e-mail address. Please check your inbox`}
    >
      If you are not <span className={Styles.Email}>{auth.currentUser.email}</span> you can <a href="/" onClick={signOut}>Sign Out</a>.
    </Card>
  )
}
