import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';

import * as Actions from '../../../../redux/actions';
import useProductTypes from '../../../../hooks/useProductTypes';

import Styles from './index.module.scss';
import { InputNumber } from 'primereact/inputnumber';

export function Header({ index, data }) {

  const dispatch = useDispatch();
  const typeDropdown = React.useRef()

  const cardAmount = useSelector(state => state.formula.products.length)
  const formulaName = useSelector(state => state.formula.name)

  const productType = useProductTypes(data.type)
  const productTypes = useProductTypes()

  const [editingType, setEditingType] = React.useState(false)

  React.useEffect(() => {
    if (typeDropdown.current) {
      const func = editingType ? typeDropdown.current.showOverlay : typeDropdown.current.hideOverlay
      func.bind(typeDropdown.current)()
    }
  }, [editingType])

  function handleDelete() {
    if (window.confirm(`Confirm deleting ${productType.name} at position ${index+1} on ${formulaName}`)) {
      dispatch(Actions.deleteProduct(index))
    }
  }

  function handleEdit() {
    dispatch(Actions.uiEditProduct(index))
  }

  function handleUpdateProductType({ value }) {
    setEditingType(false)
    dispatch(Actions.updateProduct(index, {
      type: value,
      minFob: productTypes.find(it => it.id === value)?.minFob || 0,
      maxFob: productTypes.find(it => it.id === value)?.maxFob || 0,
    }))
  }

  const buttons = (
    <ul className={Styles.HeaderButtons}>
      { cardAmount > 1 && (
        <li>
          <i className={`fa fa-trash-alt ${Styles.DeleteButton}`} title="Delete" onClick={handleDelete}/>
        </li>
      )}
      <li>
        <i className={'fa fa-pencil-alt'} title="Edit" onClick={handleEdit}/>
      </li>
    </ul>
  )

  const productName = <>
    <span className={Styles.ProductName}>{productType.name || 'Invalid'}</span>
    <i className={`fa fa-caret-down ${Styles.EditTypeButton}`} title="Change Product" onClick={() => setEditingType(!editingType)}/>
  </>

  return (
    <>
      <div style={{ borderColor: data.color }} className={Styles.Header}>
        <InputNumber
          suffix="%"
          minFractionDigits={1}
          maxFractionDigits={1}
          min={0}
          max={100}
          value={data.percentage}
          onValueChange={e => dispatch(Actions.updateProduct(index, { ...data, percentage: e.value }))}
          inputClassName={Styles.PercentageInput}
          style={{ marginRight: 4 }}
          autofocus
        />
        { productName }
        <br/>
        <Dropdown
          ref={typeDropdown}
          appendTo={document.body}
          className={Styles.EditTypeDropdown}
          options={productTypes.map((it) => ({ value: it.id, label: it.name }))}
          onChange={handleUpdateProductType}
        />
        { buttons }
      </div>
    </>
  )
}
