import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../../redux/actions';

import { store } from '../../../';
import EditProductDialog from '../FormulasPage/EditProductDialog';
import PlaceOfBlendingSelector
  from '../../utils/selectors/PlaceOfBlendingSelector';
import FinalDestinationSelector
  from '../../utils/selectors/FinalDestinationSelector';

export function startEdit(index) {
  const dispatch = store.dispatch
  const { id, name, products } = store.getState().compare.formulas[index]

  dispatch(Actions.loadFormula(id, name, products, false))
  dispatch(Actions.compEditFormula(index))
  if (products.length > 0) {
    dispatch(Actions.uiEditProduct(0))
  }
}

export function TemporaryEditor() {
  const dispatch = useDispatch()
  const index = useSelector(state => state.compare.editingIndex)
  const formula = useSelector(state => state.compare.formulas[index])

  function handleEditSave(products) {
    dispatch(Actions.compUpdateProducts(index, products))
  }

  function footer(buttons) {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 100, textAlign: 'left', display: 'flex' }}>
          <div>
            <label style={{ fontWeight: 'bold', fontSize: '.8rem'}} htmlFor={`${index}-blending`}>Custom Place of Blending</label>
            <br/>
            <PlaceOfBlendingSelector
              id={`${index}-blending`}
              value={formula?.blending}
              onChange={(id) => dispatch(Actions.compUpdateBlending(index, id))}
              allowClear
            />
          </div>
          <div style={{ marginLeft: 8 }}>
            <label style={{ fontWeight: 'bold', fontSize: '.8rem'}} htmlFor={`${index}-destination`}>Custom Final Destination</label>
            <br/>
            <FinalDestinationSelector
              id={`${index}-destination`}
              value={formula?.destination}
              onChange={(id) => dispatch(Actions.compUpdateDestination(index, id))}
              style={{ minWidth: 'initial' }}
              allowClear
            />
          </div>
        </div>
        {buttons}
      </div>
    )
  }

  return (
    <EditProductDialog onSave={handleEditSave} customFooter={footer}/>
  )
}
