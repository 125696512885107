import React from 'react';
import { ApiContext } from '../providers/ApiProvider';
import { NotificationContext } from '../providers/NotificationProvider';
import { PRODUCT_TYPES } from '../data';

export default (id, defaultValue) => {
  const api = React.useContext(ApiContext)
  const notifications = React.useContext(NotificationContext)

  const generateDefaultValue = () =>  typeof id === 'number' ? { name: 'INVALID' } : []

  const [productTypes, setProductTypes] = React.useState(defaultValue || generateDefaultValue());
  React.useEffect(() => {
    async function loader() {
      const productTypes = await PRODUCT_TYPES.get(api, notifications)

      if (typeof id === 'number') {
        setProductTypes(productTypes.find(it => it.id === id))
      } else {
        setProductTypes(productTypes);
      }
    }
    loader()
  }, [api, id, notifications])
  return productTypes;
}
