import React from 'react';

import { TransitModeDisplay } from '../../../../const';
import { usePassageModes, usePorts } from '../../../../hooks';

import { Bold } from '../../../CommonStyles.module.scss';
import Styles from './index.module.scss';

export function Body({ data }) {

  const [port] = usePorts(data.port)
  const [passageMode] = usePassageModes(data.passageMode)

  function show(label, data) {
    return (
      <>
        <span className={Bold} style={{ marginRight: '.25rem' }}>{label}:</span>
        <span>{data}</span>
      </>
    )
  }

  function currency(value) {
    return value.toFixed(2)
  }

  return (
    <div className={Styles.BodyWrapper}>
      <div>
        { show('Port', port?.name || 'Local (None)') }
      </div>
      <div>
        { show('FOB Price', currency(data.maxFob) + ' USD') }
      </div>
      <div>
        { show('Sea Freight', currency(data.seaFreight)+ ' USD/Mt') }
      </div>
      <div>
        { show('Passage Mode', passageMode?.name || 'Loading...') }
      </div>
      <div>
        { show('Transit', TransitModeDisplay[data.transit]) }
      </div>
      <div>
        { show('Storage Duration', data.storageDuration + ' Month(s)') }
      </div>
      <div>
        { show('Storage Cost', currency(data.storageCost) + ' USD/MT/Month') }
      </div>
      <div>
        { show('Loan/cash out Duration', data.loanCashoutDuration + ' Month(s)') }
      </div>
      <div>
        { show('Bank Interest Date', data.bankInterestRate + '  %/Year') }
      </div>
      <div>
        { show('Demurrage Duration', data.demurrageDuration + '  Day(s)') }
      </div>
      <div>
        { show('Demurrage Price', currency(data.demurragePrice) + '  USD/MT/Day') }
      </div>
      <div>
        { show('Demurrage', currency(data.demurrageDuration * data.demurragePrice) + '  USD/MT') }
      </div>
    </div>
  )
}
