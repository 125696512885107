import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputNumber } from 'primereact/inputnumber';

import * as Actions from '../../redux/actions';

const styles = {
  height: 24,
  width: 64,
  border: 'solid 1px transparent',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  fontSize: '.75rem'
}

export default function CurrencyEditor() {
  const dispatch = useDispatch()

  const fcfa = useSelector(state => state.currency.fcfa)
  const ghs = useSelector(state => state.currency.ghs)

  return (
    <div style={{ fontSize: '.75rem' }}>
      <span style={{ fontWeight: 'bold' }}>Currency:</span>
      <div>
        1 USD =
        <InputNumber
          value={fcfa}
          mode="decimal"
          minFractionDigits={2}
          maxFractionDigits={2}
          onChange={e => dispatch(Actions.currencySetFcfa(e.value))}
          inputStyle={styles}
        />
        FCFA
        <i
          className="pi pi-refresh"
          title="Reset"
          style={{ cursor: 'pointer' }}
          onClick={() => dispatch(Actions.currencyResetFcfa())}
        />
      </div>
      <div>
        1 USD =
        <InputNumber
          value={ghs}
          mode="decimal"
          minFractionDigits={2}
          maxFractionDigits={2}
          onChange={e => dispatch(Actions.currencySetGhs(e.value))}
          inputStyle={styles}
        />
        GHS
        <i
          className="pi pi-refresh"
          title="Reset"
          style={{ marginLeft: '.25rem', cursor: 'pointer' }}
          onClick={() => dispatch(Actions.currencyResetGhs())}
        />
      </div>
    </div>
  )

}
