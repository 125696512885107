import lazy from './lazy';
import lazyAsync from 'lazyasfk/src/async';
import { auth, firestore } from './services/firebase';

export const PRODUCT_TYPES = lazy(async (api, notificationContext) => {
    try {
        const { data } = await api.getProductTypes()
        return data
    } catch(e) {
        console.error(e)
        if (notificationContext) {
            notificationContext.sendError('Failed to load the products list', 'Please refresh the page and try again')
        }
    }
})

export const IS_ADMIN = lazy(async (api) => {
    try {
        const { data } = await api.isAdmin();
        return data.isAdmin;
    } catch (e) {
        return undefined; // To attempt to reload at the next access
    }
})

export const OWN_FORMULAS = lazyAsync(async () => {
  try {
    const inLocalStorage = JSON.parse(localStorage.getItem('@fcc/ownFormulas'))
    if (
      inLocalStorage.data
      && +new Date() - inLocalStorage.date <= 10 * 60 * 1000
      && inLocalStorage.email === auth.currentUser?.email
    ) {
      console.log(`[INFO] Valid ${auth.currentUser?.email}'s formulas found in the local storage`)
      return inLocalStorage.data
    }
  } catch (e) { }

  console.log(`[INFO] Syncing ${auth.currentUser?.email}'s formulas with Firestore`)
  const querySnapshot = await firestore.collection("formulas")
    .where("email", "==", auth.currentUser?.email)
    .get()

  const data = querySnapshot.docs.map(it => ({ id: it.id, ...it.data() }))
  localStorage.setItem('@fcc/ownFormulas', JSON.stringify({ date: +new Date(), email: auth.currentUser?.email, data }))

  return data
})

export const PUBLIC_FORMULAS = lazyAsync(async () => {

  try {
    const inLocalStorage = JSON.parse(localStorage.getItem('@fcc/publicFormulas'))
    if (inLocalStorage.data && +new Date() - inLocalStorage.date <= 10 * 60 * 1000) {
      console.log(`[INFO] Valid public formulas found in the local storage`)
      return inLocalStorage.data
    }
  } catch (e) {

  }

  console.log("[INFO] Syncing public formulas with Firestore")
  const querySnapshot = await firestore.collection("formulas")
    .where("public", "==", true)
    .get()

  const data = querySnapshot.docs.map(it => ({ id: it.id, ...it.data() }))
  localStorage.setItem('@fcc/publicFormulas', JSON.stringify({ date: +new Date(), data }))

  return data
})
