import React from 'react';
import {
  allOwn,
  allPublic,
  queryRemoteFormulas,
} from '../../../hooks/queryRemoteFormulas';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';

import Styles from './FormulaPicker.module.scss';

import * as Actions from '../../../redux/actions';
import { Card } from 'primereact/card';
import { useProductTypes } from '../../../hooks';
import {auth} from "../../../services/firebase";

export default function FormulaPicker({ style }) {
  const dispatch = useDispatch()

  const pickedFormulas = useSelector(state => state.compare.formulas)
  const possibleFormulas = queryRemoteFormulas([ allOwn(), allPublic() ])

  const productTypes = useProductTypes()


  const [visible, setVisible] = React.useState(false)

  function PickedFormulaItem({ index }) {
    const formula = pickedFormulas[index]
    const { products } = formula;

    return (
      <div className={`p-col-12 p-sm-6 p-md-4 p-lg-3 ${Styles.PickedFormulaItem}`}>
        <span className="p-overlay-badge">
          <Card title={formula.name}>
            <ul>
              { products.map((it, index) => <li key={index}>{it.percentage}% {productTypes.find(t => t.id === it.type)?.name || 'INVALID'}</li>) }
            </ul>
          </Card>
          <span
            className="p-badge p-badge-warning"
            title="Remove from comparison"
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch(Actions.compRmFormula(index))}
          >
            <i className="fa fa-trash"/>
          </span>
        </span>
      </div>
    )
  }

  return (
    <>
      <Button label="Select the formulas" onClick={() => setVisible(true)} style={{ width: '100%', ...style }}/>
      <Dialog
        footer={<Button label="Done" onClick={() => setVisible(false)}/>}
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: '80vw' }}
        contentStyle={{ overflow: 'hidden', display: 'flex', paddingBottom: 0 }}
        closable={false}
        modal
      >
        <div className="p-grid" style={{ flexGrow: 100 }}>

          <div className={`p-col-3 ${Styles.FormulaPicker}`}>
            <h1 style={{ margin: 0, padding: 0 }}>Pick the Formulas</h1>
            <span style={{ fontSize: '.75rem' }}>Use the <i className="fa fa-plus"/> button</span>
            <ul style={{ padding: 0 }}>
              { possibleFormulas.map(formula => (
                <li
                  className={Styles.FormulaItem}
                  onClick={() => dispatch(Actions.compAddFormula(formula))}
                >
                  <i className={`fa fa-plus ${Styles.Plus}`} title={`Add ${formula.name}`}/>
                  <span className={Styles.FormulaName}>{formula.name}</span>
                  { formula.public && <i className="fa fa-lock-open" title="Public Formula" style={{ fontSize: '.65rem', verticalAlign: 'text-top' }}/> }
                  { (formula.email === auth.currentUser.email) && <i className="fa fa-user-lock" title="Private Formula" style={{ fontSize: '.65rem', verticalAlign: 'text-top' }}/> }
                </li>
              )) }
            </ul>
          </div>

          <div className={`p-col ${Styles.PickedFormulas}`}>
            <h1 style={{ margin: 0, padding: 0 }}>Formulas to compare</h1>
            { pickedFormulas.length === 0 && <span style={{ fontSize: '.75rem' }}>Please pick at least one formula</span>}

            <div className="p-grid" style={{ overflowX: 'hidden' }}>
              { pickedFormulas.map((_, index) => <PickedFormulaItem index={index}/>) }
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )

}
