import React from 'react';
import useSearchParam from '../../../../hooks/useSearchParam';
import { setQueryParameter } from '../../../../utils';
import { useHistory } from 'react-router';
import PlaceOfBlendingSelector from '../PlaceOfBlendingSelector';

export function useUrlPlaceOfBlending() {
  return parseInt(useSearchParam('blending')) || null
}

export function UrlPlaceOfBlendingSelector({ id, allowNone }) {
  const history = useHistory()
  const value = useUrlPlaceOfBlending()

  return (
    <PlaceOfBlendingSelector
      id={id}
      value={value}
      allowNone={allowNone}
      onChange={id => setQueryParameter(history, 'blending', id)}
    />
  )
}
