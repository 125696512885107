import React from 'react';
import { addTypeDataToProduct, useDeepCompareMemoized } from '../utils';
import FormulaCalculationDescription
  from '../calculation/FormulaCalculationDescription';
import { useFinalDestinations, useProductTypes } from './index';
import usePortCharges from './usePortCharges';
import useLandTransports from './useLandTransports';

export default (formulas, destinationId, placeOfBlendingId) => {

  const [destinations] = useFinalDestinations()
  const [landTransports] = useLandTransports()
  const [charges] = usePortCharges()
  const types = useProductTypes()

  const deps = useDeepCompareMemoized([ destinationId, placeOfBlendingId, formulas, destinations, landTransports, charges, types ])
  return React.useMemo(() => {
    if (destinationId && destinations.length > 0 && landTransports.length > 0 && charges.length > 0 && types.length > 0) {
      return formulas.map(formula => {
        const destination = destinations.find(it => it.id === (formula.destination || destinationId))
        const description = new FormulaCalculationDescription(
          formula.products.map(product => addTypeDataToProduct(product, types)),
          destination,
          formula.blending || placeOfBlendingId,
          landTransports,
          charges
        )

        return {formula, results: description.results}
      })
    } else {
      return []
    }
  }, deps)

}
