import React from 'react';
import { useLocation } from 'react-router';

export default function useSearchParam(key, defaultValue = undefined) {
  const { search } = useLocation()

  return React.useMemo(() => {
    const found = search.replace('?', '')
      .split('&')
      .map(it => it.split('='))
      .find(([k]) => k === key)
      ?.[1]

    return found === undefined ? defaultValue : found
  }, [defaultValue, key, search])
}
