import React from 'react';

import { Toast } from 'primereact/toast';

export const NotificationContext = React.createContext({ toast: null })

export function NotificationProvider({ children }) {
  const toast = React.useRef();

  const toastWrapper = ({
    toast: toast?.current,
    show: toast?.current?.show,
    sendSuccess: (summary, details) => toast?.current?.show({ severity: 'success', summary, details }),
    sendInfo: (summary, details) => toast?.current?.show({ severity: 'info', summary, details }),
    sendError: (summary, details) => toast?.current?.show({ severity: 'error', summary, details }),
    sendWarning: (summary, details) => toast?.current?.show({ severity: 'warning', summary, details })
  })

  return (
    <NotificationContext.Provider value={toastWrapper}>
      <Toast ref={toast} position="bottom-right"/>
      { children }
    </NotificationContext.Provider>
  );
}
