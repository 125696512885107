import React from 'react';

import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import classNames from 'classnames';

import { auth } from '../../../services/firebase';
import { EMAIL_REGEX } from '../../../const';
import Styles from './ResetPassword.module.scss';
import { Link } from 'react-router-dom';

export default function ResetPassword() {

  const [email, setEmail] = React.useState('');
  const [emailSent, setEmailSent] = React.useState(false);
  const [error, setError] = React.useState('');

  function handleSubmit() {
    if (EMAIL_REGEX.test(email.toLowerCase())) {
      auth
        .sendPasswordResetEmail(email)
        .then(() => setEmailSent(true))
        .catch(() => setError('An error occurred on resetting your password. Please try again later'))
    }
  }

  const header = <div className={Styles.Header}>
    <Link to="/" className={Styles.BackToLogin}>
      <i className="fa fa-chevron-left" title="Back to Login"/>
    </Link>
    <h1 className={Styles.Title}>Reset Password</h1>
  </div>
  const footer = <Button className={Styles.SubmitButton} label="Send me a recovery e-mail" onClick={handleSubmit} />
  const subtitle = error || (emailSent ? 'A recovery link was sent to your e-mail address. Please check your inbox to proceed' : '')

  return (
    <Card
      header={header}
      className={classNames(Styles.ResetPasswordScreen, error ? Styles.Error : emailSent ? Styles.Success : '')}
      footer={footer}
      subTitle={subtitle}
      >
      <div className={Styles.FormField}>
        <span className="p-float-label">
          <InputText
            name="email"
            type="email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            required
          />
          <label htmlFor="email">E-mail address</label>
        </span>
      </div>
    </Card>
  )
}
