import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router';
import { Menubar } from 'primereact/menubar';
import { TieredMenu } from 'primereact/tieredmenu';

import { auth } from '../../../services/firebase';

import useIsAdmin from '../../../hooks/useIsAdmin';

import Styles from './index.module.scss';
import { FormulasPage } from '../FormulasPage';
import { ForumulaCalculator } from '../FormulaCalculator';
import { UserContext } from '../../../providers/UserProvider';
import CompareFormulas from '../CompareFormulas';
import HomePage from './HomePage';
import AboutUs from './AboutUs';
import { FormulaListPage } from '../FormulasPage/FormulaListPage';
import ShareUrlHandler from '../CompareFormulas/ShareUrlHandler';

export function UserDashboard({ history }) {
  const isAdmin = useIsAdmin()
  const user = React.useContext(UserContext)
  const userMenuRef = React.useRef()

  const { path } = useRouteMatch()
  function goTo(page) {
      history.push(`${path}${page}`)
  }

  const menuModel = [
    {
      label: 'Home',
      command: () => goTo('/')
    },
    {
        label: 'My Formulas',
        command: () => goTo('/formula')
    },
    {
      label: 'Public Formulas',
      command: () => goTo('/formula/public')
    },
    {
      label: 'Compare Formulas',
      command: () => goTo('/formulas/compare')
    }
  ]

  const userMenuModel = [
    {
      label: `${user.displayName}`,
      style: { textAlign: 'center' }
    },
    {
      separator: true
    },
    {
      label: 'Administrative Dashboard',
      icon: 'fa fa-cog',
      style: { display: isAdmin ? 'block' : 'none' },
      url: '/admin'
    },
    {
      label:'Quit',
      icon:'fa fa-power-off',
      command: () => auth.signOut()
    }
  ]

  const menubarStart = <>
    <img alt="IFDC Logo" src="/logo.png" style={{ height: 32, marginRight: '2em' }} onClick={() => goTo('/')}/>
  </>

  const menubarEnd = <nav className={Styles.MenubarEnd}>
    <a onClick={() => goTo('/about')} href="#" className={Styles.AboutUs}>About us</a>
    <img
      alt="User Avatar"
      src={user.photoURL || '/user.svg'}
      style={{ height: 42, borderRadius: '50%' }}
      onClick={(e) => userMenuRef.current?.toggle(e)}
    />
    <TieredMenu
      ref={userMenuRef}
      model={userMenuModel}
      popup
    />
  </nav>

  return (
    <main className={Styles.UserDashboard}>
      <section className={Styles.PageWrapper}>
        <header className={Styles.Header}>
          <Menubar model={menuModel} start={menubarStart} end={menubarEnd}/>
        </header>
        <section className={Styles.Page}>
          <Switch>
            <Route path="/my/formula/public">
              <FormulaListPage publicFormulas/>
            </Route>

            <Route path="/my/formulas/compare/shared" exact component={ShareUrlHandler}/>
            <Route path="/my/formula/public">
              <FormulaListPage publicFormulas/>
            </Route>

            <Route path="/my/formulas/compare" component={CompareFormulas}/>

            <Route path="/my/formula/new" component={FormulasPage}/>
            <Route path="/my/formula/:formulaId" component={FormulasPage}/>
            <Route path="/my/formula" component={FormulaListPage}/>

            <Route path="/my/calculate" component={ForumulaCalculator}/>
            <Route path="/my/about" component={AboutUs}/>
            <Route path="/my" component={HomePage} exact/>
          </Switch>
        </section>
      </section>
    </main>
  )
}
