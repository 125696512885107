import React from 'react';

import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { ToggleButton } from 'primereact/togglebutton';
import { Dropdown } from 'primereact/dropdown';

import { Bold } from '../../../CommonStyles.module.scss';
import Styles from './index.module.scss';

export function FormulaInputForm({ onChange }) {

  const indexValues = (values) => values.map((label, value) => ({ label, value }))

  const nitrogenFromModel = indexValues(['Urea', 'Ammonium Sulfate', 'Ammonium Nitrate'])
  const phosphorusFromModel = indexValues(['Diammonium Phosphate (DAP)', 'Monoammonium Phosphate (MAP)'])
  const potassiumFromModel = indexValues(['Muriate of Potash (MOP)', 'Sulfate of Potash (SOP)'])
  const magnesiumFromModel = indexValues(['Sulpomag or KMAG', 'Another material'])

  const [formulaOption, setFormulaOption] = React.useState(0);
  const [useFiller, setUseFiller] = React.useState(true);
  const [nitrogenFrom, setNitrogenFrom] = React.useState(0);
  const [phosphorusFrom, setPhosporusFrom] = React.useState(0);
  const [potassiumFrom, setPotassiumFrom] = React.useState(0);
  const [magnesiumFrom, setMagnesiumFrom] = React.useState(0);

  const [mgO, setMgO] = React.useState(0);
  const [fe, setFe] = React.useState(0);
  const [mn, setMn] = React.useState(0);
  const [zn, setZn] = React.useState(0);
  const [b, setB] = React.useState(0);
  const [cu, setCu] = React.useState(0);

  function setter(setter) {
    return ({ value }) => setter(value)
  }

  function compileData() {
    return {
      formulaOption,
      useFiller,
      nitrogenFrom,
      phosphorusFrom,
      potassiumFrom,
      magnesiumFrom,
      nutrientContents: { mgO, fe, mn, zn, b, cu }
    }
  }

  React.useEffect(() => onChange && void onChange(compileData()), [
    formulaOption,
    useFiller,
    nitrogenFrom,
    phosphorusFrom,
    potassiumFrom,
    magnesiumFrom,
    mgO, fe, mn, zn, b, cu
  ])

  return (
    <>
      <div>
        <span className={Bold}>I want to:</span>
        <div>
          <RadioButton
            id="formulaOption0"
            value={0}
            checked={formulaOption === 0}
            onChange={setter(setFormulaOption)}
          />
          <label htmlFor="formulaOption0" className="p-radiobutton-label">Produce a specific formula</label>
        </div>
        <div>
          <RadioButton
            id="formulaOption1"
            value={1}
            checked={formulaOption === 1}
            onChange={setter(setFormulaOption)}
          />
          <label htmlFor="formulaOption1" className="p-radiobutton-label">Formulate based on fertilizer rates</label>
        </div>
      </div>
      <div>
        <span className={Bold}>Use Filler: </span><br/>
        <ToggleButton checked={useFiller} onChange={setter(setUseFiller)} />
      </div>
      <div>
        <span className={Bold}>Nitrogen From:</span><br/>
        <Dropdown
          options={nitrogenFromModel}
          value={nitrogenFrom}
          onChange={setter(setNitrogenFrom)}
          placeholder="Select a product"
        />
      </div>
      <div>
        <span className={Bold}>Phosphorus From:</span><br/>
        <Dropdown
          options={phosphorusFromModel}
          value={phosphorusFrom}
          onChange={setter(setPhosporusFrom)}
          placeholder="Select a product"
        />
      </div>
      <div>
        <span className={Bold}>Potassium From:</span><br/>
        <Dropdown
          options={potassiumFromModel}
          value={potassiumFrom}
          onChange={setter(setPotassiumFrom)}
          placeholder="Select a product"
        />
      </div>
      <div>
        <span className={Bold}>Magnesium From:</span><br/>
        <Dropdown
          options={magnesiumFromModel}
          value={magnesiumFrom}
          onChange={setter(setMagnesiumFrom)}
          placeholder="Select a product"
        />
      </div>
      <div className={Styles.NutrientContent}>
          <span className={Bold}>Nutrient content of materials:</span>
          <div style={{ display: magnesiumFrom === 1 ? 'block' : 'none' }}>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">% MgO</span>
                <InputNumber min={0} prefix="%" value={mgO} onChange={setter(setMgO)} suffix="%" />
            </div>
          </div>
          <div>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">% Fe</span>
                <InputNumber min={0} value={fe} onChange={setter(setFe)} suffix="%" />
            </div>
          </div>
          <div>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">% Mn</span>
                <InputNumber min={0} value={mn} onChange={setter(setMn)} suffix="%"  />
            </div>
          </div>
          <div>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">% Zn</span>
                <InputNumber min={0} value={zn} onChange={setter(setZn)} suffix="%" />
            </div>
          </div>
          <div>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">% B</span>
                <InputNumber min={0} value={b} onChange={setter(setB)} suffix="%" />
            </div>
          </div>
          <div>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">% Cu</span>
                <InputNumber min={0} value={cu} onChange={setter(setCu)} suffix="%" />
            </div>
          </div>
        </div>
    </>
  )
}
