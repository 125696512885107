import React from 'react';
import useErrorHandler from '../useErrorHandler';

export default (getOne, getAll, displayName) => (id) => {
  const errorHandler = useErrorHandler(`Could not load the existing ${displayName}`);

  const [data, setData] = React.useState(typeof id === 'number' ? undefined : []);
  const [loading, setLoading] = React.useState(false);

  function loader() {
    setLoading(true)
    const provider = typeof id === 'number' ? getOne : getAll

    provider(id)
      .then(({ data }) => {
        setData(data)
        setLoading(false)
      }).catch(errorHandler)

  }

  React.useEffect(loader, [id])

  return [data, loading, loader];
}
