import React from 'react';

import { InputNumber } from 'primereact/inputnumber';
import PassageModeSelector
  from '../../../../utils/selectors/PassageModeSelector';
import PortSelector from '../../../../utils/selectors/PortSelector';

import Styles from './index.module.scss';
import TransitModeSelector
  from '../../../../utils/selectors/TransitModeSelector';

export function EditProductForm({ data, onChange }) {

  const {
    transit, maxFob, seaFreight, storageDuration, passageMode, storageCost, loanCashoutDuration,
    bankInterestRate, demurrageDuration, demurragePrice, port
  } = data

  function setter(dataChanged) {
    onChange({ ...data, ...dataChanged })
  }

  return (
    <div className={Styles.Form}>

      <div className="p-fluid p-formgrid p-grid">
        <div className={`p-col ${Styles.Card}`}>
          <h4>FOB Prices</h4>
          <label htmlFor="maxFob">FOB Price at Origin</label>
          <InputNumber
            id="maxFob"
            mode="currency"
            currency="USD"
            value={maxFob}
            onValueChange={e => setter({ maxFob: e.value })}
          />
        </div>
        <div className={`p-col ${Styles.Card}`}>
          <h4>CIF Prices</h4>
          <div>
            <label htmlFor="port">Port of Origin</label>
            <PortSelector
              id="port"
              value={port}
              onChange={port => setter({ port })}
              allowNone
            />
          </div>
          <div>
            <label htmlFor="seaFreight">Sea Freight</label>
            <InputNumber
              id="seaFreight"
              mode="currency"
              currency="USD"
              suffix={" / MT"}
              value={seaFreight}
              onValueChange={e => setter({ seaFreight: e.value })}
            />
          </div>
        </div>
        <div className={`p-col ${Styles.Card}`}>
          <h4>Transport Mode</h4>
          <div>
            <label htmlFor="passageMode">Passage Mode</label>
            <PassageModeSelector
              id="passageMode"
              value={passageMode}
              onChange={e => setter({ passageMode: e })}
            />
          </div>
          <div>
            <label htmlFor="transit">Transit Mode</label>
            <TransitModeSelector
              id="transit"
              value={transit}
              onChange={transit => setter({ transit })}
            />
          </div>
        </div>
      </div>
      <hr/>

      <div className="p-fluid p-formgrid p-grid">
        <div className={`p-col ${Styles.Card}`}>
          <h4>Finance</h4>
          <div>
            <label htmlFor="loalCashoutDuration">Loan/Cash out duration</label>
            <InputNumber
              id="loanCashoutDuration"
              min={0}
              value={loanCashoutDuration}
              onValueChange={e => setter({ loanCashoutDuration: e.value })}
              suffix={" Month(s)"}
            />
          </div>
          <div>
            <label htmlFor="bankInterestRate">Bank Interest Rate</label>
            <InputNumber
              id="bankInterestRate"
              value={bankInterestRate}
              onValueChange={e => setter({ bankInterestRate: e.value })}
              min={0}
              max={100}
              suffix="%"
            />
          </div>
        </div>
        <div className={`p-col ${Styles.Card}`}>
          <h4>Storage</h4>
          <div>
            <label htmlFor="storageDuration">Storage Duration</label>
            <InputNumber
              id="storageDuration"
              value={storageDuration}
              onValueChange={e => setter({ storageDuration: e.value })}
              min={0}
              suffix={" Month(s)"}
            />
          </div>
          <div>
            <label htmlFor="storageCost">Storage Cost</label>
            <InputNumber
              id="storageCost"
              mode="currency"
              currency="USD"
              suffix={" / MT / Month"}
              value={storageCost}
              onValueChange={e => setter({ storageCost: e.value })}
            />
          </div>
        </div>
        <div className={`p-col ${Styles.Card}`}>
          <h4>Demurrage</h4>
          <div>
            <label htmlFor="demurrageDuration">Demurrage Duration</label>
            <InputNumber
              id="demurrageDuration"
              suffix={" Day(s)"}
              value={demurrageDuration}
              onValueChange={e => setter({ demurrageDuration: e.value })}
            />
          </div>
          <div>
            <label htmlFor="demurragePrice">Demurrage Price</label>
            <InputNumber
              id="demurragePrice"
              mode="currency"
              currency="USD"
              min={0}
              value={demurragePrice}
              onValueChange={e => setter({ demurragePrice: e.value })}
              suffix={" / MT / Day"}
            />
          </div>
        </div>
      </div>

    </div>
  );
}
