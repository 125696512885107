import React from 'react';
import useSearchParam from '../../../../hooks/useSearchParam';
import FinalDestinationSelector from '../FinalDestinationSelector';
import { setQueryParameter } from '../../../../utils';
import { useHistory } from 'react-router';

export function useUrlFinalDestination() {
  return parseInt(useSearchParam('destination')) || null
}

export function UrlFinalDestinationSelector({ id }) {
  const history = useHistory()
  const value = useUrlFinalDestination()

  return (
    <FinalDestinationSelector
      id={id}
      value={value}
      onChange={id => setQueryParameter(history, 'destination', id)}
    />
  )
}
