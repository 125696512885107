import { Types } from '../actions/';

const defaultState = Object.freeze({
  fcfa: 580,
  ghs: 5.51
})

export default (state = defaultState, action) => {
  switch (action.type) {

    case Types.SET_FCFA: return {
      ...state,
      fcfa: action.payload
    }

    case Types.SET_GHS: return {
      ...state,
      ghs: action.payload
    }

    case Types.RESET_FCFA: return {
      ...state,
      fcfa: defaultState.fcfa
    }

    case Types.RESET_GHS: return {
      ...state,
      ghs: defaultState.ghs
    }

    default: return state
  }
}
