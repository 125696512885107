import React from 'react';

import { Card } from 'primereact/card';

import Styles from './index.module.scss';
import useProductTypes from '../../../../hooks/useProductTypes';
import {
  allOwn,
  allPublic,
  queryRemoteFormulas,
} from '../../../../hooks/queryRemoteFormulas';
import { Link } from 'react-router-dom';

export function FormulaListPage({ publicFormulas = false }) {

  const formulas = queryRemoteFormulas([ publicFormulas ? allPublic() : allOwn() ])
  const productTypes = useProductTypes()

  return (
    <main className={Styles.FormulasPage}>
      <ul className={Styles.Grid}>
        {
          formulas.map(({ id, name, products, public: isPublic }) => (
            <Link to={publicFormulas ? id : `formula/${id}`}>
              <li key={id} className="p-overlay-badge">
                <Card title={name}>
                  <ul>
                    { products.map(({ type, percentage }, index) => <li key={index}>{percentage}% {productTypes.find(it => it.id === type)?.name || 'INVALID'}</li>)}
                  </ul>
                </Card>
                { !publicFormulas && (
                  <span
                    style={{ marginRight: 16 }}
                    className={`p-badge ${isPublic ? 'p-badge-success' : 'p-badge-warning'}`}
                  >
                  <i
                    style={{ fontSize: '.75rem' }}
                    className={`fa fa-${isPublic ? 'lock-open' : 'lock'}`}
                    title={isPublic ? 'Public' : 'Private'}
                  />
                </span>
                ) }
              </li>
            </Link>
          ))
        }
        { !publicFormulas && (
          <Link to="formula/new">
            <li>
              <Card id={'0'} style={{ textAlign: 'center' }} className={Styles.New}>
                <i className="fa fa-plus"/>
              </Card>
            </li>
          </Link>
        ) }
      </ul>
    </main>
  )
}
