import React from 'react';

import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

import { auth, signInWithGoogle } from '../../../services/firebase';
import { EMAIL_REGEX } from '../../../const';

import Styles from './LoginScreen.module.scss';
import CommonStyles from '../../CommonStyles.module.scss';

export default function LoginScreen() {

  const [triedOnce, setTriedOnce] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [pwd, setPwd] = React.useState('')
  const [error, setError] = React.useState('')

  function handleLogin() {
    setTriedOnce(true)
    if (EMAIL_REGEX.test(email)) {
      setLoading(true)
      auth.signInWithEmailAndPassword(email, pwd)
        .catch((e) => {
          console.error(e)
          setError('Your e-mail address or password doesn\'t match. Please try again')
          setLoading(false)
        })
    }
  }

  const renderLoading = () => <ProgressSpinner/>

  const renderForm = () => <>
    <img src="./logo.png" style={{ height: '4em' }} />
    <br/>
    <h3 style={{ margin: '0' }}>Fertilizer Cost Simulator</h3>
    <h5 style={{ margin: '0' }}>Taking knowledge from Port to Farm</h5>
    { error ? <span className={CommonStyles.RedText}>{error}</span>: '' }
    <div className={Styles.FormField}>
          <span className="p-float-label">
            <InputText
              name="email"
              type="email"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              required
            />
            <label htmlFor="email">E-mail address</label>
          </span>
      { triedOnce && !email && <span className={CommonStyles.RedText}>You must provide an email address</span> }
    </div>
    <div className={Styles.FormField}>
          <span className="p-float-label">
          <InputText
            name="pwd"
            type="password"
            value={pwd}
            onChange={({ target }) => setPwd(target.value)}
            required
          />
            <label htmlFor="pwd">Password</label>
          </span>
      { email && !pwd && <span className={CommonStyles.RedText}>You must provide a password</span> }
    </div>
    <div className={Styles.ForgotPwd}>
      <Link to="resetPassword">I forgot my password</Link>
    </div>
    <div className={Styles.FormButtons}>
      <Button label="Login" onClick={handleLogin} />
      <Link to="/register">
        <Button label="Sign Up" className="p-button-secondary"/>
      </Link>
    </div>
    <span>Do not have an account? <Link to="/register">Sign up</Link> instead</span>
  </>

  return (
    <main className={Styles.LoginScreen}>
      <Card className={Styles.LoginForm}>
        { loading ? renderLoading() : renderForm() }
      </Card>
      <h1 className={Styles.SubTitle}>Or Sign In with</h1>
      <ul className={Styles.LoginMethodsContainer}>
        <li>
          <i
            className="pi pi-google"
            title="Login with Google"
            onClick={loading ? ()=>{} : signInWithGoogle}
          />
        </li>
      </ul>
    </main>
  )
}
