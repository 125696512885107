import React from 'react';

import { Menu } from 'primereact/menu';
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router';

import { auth } from '../../../services/firebase';
import FinalDestinationMaintenance
  from './maintenance/FinalDestinationMaintenance';
import PortMaintenance from './maintenance/PortMaintenance';
import ProductTypeMaintenance from './maintenance/ProductTypeMaintenance';
import PassageModeMaintenance from './maintenance/PassageModeMaintenance';
import CountryMaintenance from './maintenance/CountryMaintenance';

import Styles from './index.module.scss';
import TownMaintenance from './maintenance/TownMaintenance';
import PortChargesMaintenance from './maintenance/PortChargesMaintenance';
import LandTransportMaintenance from './maintenance/LandTransportMaintenance';

export default function AdminScreen({ history }) {

  const { path } = useRouteMatch()
  const { pathname } = useLocation()

  function goTo(page) {
    return () => history.push(`${path}${page}`)
  }

  const header = (() => {
    const location = pathname.substring(path.length + 1).split('/')[0]
    switch (location) {
      case 'finalDestination': return 'Final Destinations'
      case 'port': return 'Port'
      case 'productType': return 'Product Types'
      case 'passageMode': return 'Passage Modes'
      case 'country': return 'Countries'
      case 'town': return 'Towns'
      case 'portCharge': return 'Port Charges'
      case 'landTransport': return 'Land Transport'
      default: return ''
    }
  })()

  const menuModel = [
    {
      label: 'Maintenance',
      icon: 'fa fa-cog',
      items: [
        {
          label: 'Countries',
          icon: 'fa fa-flag',
          command: goTo('/country'),
        },
        {
          label: 'Towns',
          icon: 'fa fa-map-marker',
          command: goTo('/town'),
        },
        {
          label: 'Final Destinations',
          icon: 'fa fa-map-pin',
          command: goTo('/finalDestination'),
        },
        {
          label: 'Ports',
          icon: 'fa fa-ship',
          command: goTo('/port'),
        },
        {
          label: 'Product Types',
          icon: 'fa fa-list',
          command: goTo('/productType'),
        },
        {
          label: 'Passage Modes',
          icon: 'fa fa-tags',
          command: goTo('/passageMode'),
        },
        {
          label: 'Port Charges',
          icon: 'fa fa-file-invoice',
          command: goTo('/portCharge')
        },
        {
          label: 'Land Transports',
          icon: 'fa fa fa-truck',
          command: goTo('/landTransport')
        }
      ]
    }
  ]

  const menuStart = <h1 style={{ margin: 0 }}>{header}</h1>

  return (
    <>
      <header className={Styles.Header}>
        <Menubar
          start={<img src="/logo.png" style={{ height: 32, marginRight: '2em' }} onClick={() => goTo('/')}/>}
          end={<Button icon="pi pi-power-off" label="Sign Out" onClick={() => auth.signOut()}/>}
          model={[
            {
              label: 'Home',
              icon: 'fa fa-home',
              url: '/my'
            }
          ]}
        />
      </header>
      <main className={Styles.AdminPage}>
        <aside className={Styles.MenuWrapper}>
          <Menu model={menuModel} className={Styles.Menu}/>
        </aside>
        <section className={Styles.PageWrapper}>
          { header && (
            <header>
              <Menubar start={menuStart} />
            </header>
          )}
          <section className={Styles.Page}>
            <Switch>
              <Route path={`${path}/landTransport`} component={LandTransportMaintenance}/>
              <Route path={`${path}/portCharge`} component={PortChargesMaintenance}/>
              <Route path={`${path}/finalDestination`} component={FinalDestinationMaintenance}/>
              <Route path={`${path}/port`} component={PortMaintenance}/>
              <Route path={`${path}/productType`} component={ProductTypeMaintenance}/>
              <Route path={`${path}/passageMode`} component={PassageModeMaintenance}/>
              <Route path={`${path}/country`} component={CountryMaintenance}/>
              <Route path={`${path}/town`} component={TownMaintenance}/>
            </Switch>
          </section>
        </section>
      </main>
    </>
  )
}
