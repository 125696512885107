import React from 'react';
import { NotificationContext } from '../../../../providers/NotificationProvider';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';

import CommonStyles from '../../../CommonStyles.module.scss';

export default function GenericMaintenanceScreen({ dataProvider, renderEditDialog, children }) {

  const notification = React.useContext(NotificationContext)

  const [loading, setLoading] = React.useState(false)
  const [editing, setEditing] = React.useState(false)
  const [editingId, setEditingId] = React.useState()


  const [data, setData] = React.useState()

  function startEdit(toEditId) {
    setEditingId(toEditId)
    setEditing(true)
  }

  function refresh() {
    setLoading(true)
    dataProvider()
      .then(({ data }) => {
        setData(data)
        setLoading(false)
      }).catch((e) => {
        console.error(e)
        setLoading(false)
        notification.sendError('Something went wrong on fetching the data', e.message)
      })
  }

  React.useEffect(refresh, [])

  const renderLoading = () => <ProgressSpinner className={CommonStyles.CenterSpinner}/>

  const renderEditing = () => renderEditDialog({
    id: editingId,
    onHide: () => { setEditingId(0); setEditing(false) },
    visible: editing,
    notify: refresh
  })

  const renderData = () => (
    <section>
      { renderEditing() }
      <ul className={CommonStyles.TableButtons}>
        <li>
          <Button label="Create" icon="fa fa-plus" onClick={() => startEdit()}/>
        </li>
      </ul>
      <span>Click in the rows to edit</span>
      <DataTable
        value={data}
        emptyMessage="No data to show"
        onRowClick={({ data }) => startEdit(data.id)}
      >
        { children }
      </DataTable>
    </section>
  )

  return loading ? renderLoading() : renderData()
}
