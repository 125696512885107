import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

function credentials() {
  const {
    REACT_APP_FIREBASE_API_KEY: apiKey,
    REACT_APP_FIREBASE_AUTH_DOMAIN: authDomain,
    REACT_APP_FIREBASE_DATABASE_URL: databaseURL,
    REACT_APP_FIREBASE_PROJECT_ID: projectId,
    REACT_APP_FIREBASE_STORAGE_BUCKET: storageBucket,
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID: messagingSenderId,
    REACT_APP_FIREBASE_APP_ID: appId
  } = process.env;
  return { apiKey, authDomain, databaseURL, projectId, storageBucket, messagingSenderId, appId }
}

firebase.initializeApp(credentials());

firebase.analytics()
export const auth = firebase.auth();
export const firestore = firebase.firestore();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();


export const mkUserDocument = async (user, data) => {
  if (user) {
    const userRef = firestore.doc(`users/${user.uid}`)
    const snapshot = await userRef.get()

    if (!snapshot.exists) {
      try {
        await userRef.set(data || {})
      } catch (e) {
        console.error(e)
      }
    }

    return getUserDocument(user.uid)
  }
}

export const getUserDocument = async (uid) => {
  if (uid) {
    try {
      const doc = await firestore.doc(`users/${uid}`).get()
      return {
        uid, ...doc.data()
      }
    } catch (e) {
      console.error(e)
    }
  }
}

export const signInWithGoogle = () => auth.signInWithPopup(googleAuthProvider)
