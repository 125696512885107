export const FormatOptions = {
  COUNTRY_ALPHA: 1
}

export function formatTown(town, options) {
  let string = `${town?.name}`
  if (options.includes(FormatOptions.COUNTRY_ALPHA)) {
    string += ` (${town?.country?.alphaCode})`
  }
  return string
}

export function formatCountry(country, options) {
  let string = `${country?.name}`
  if (options.includes(FormatOptions.COUNTRY_ALPHA)) {
    string += ` (${country?.alphaCode})`
  }
  return string
}

export function formatDestination(destination, options) {
  return formatTown(destination?.town, options)
}

export function formatPort(port, options) {
  let string = `${port?.name}`
  if (options.includes(FormatOptions.COUNTRY_ALPHA)) {
    string += ` (${port?.country?.alphaCode})`
  }
  return string
}
