import React from 'react';
import { Category } from '../../ResultsTable';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';

const model = [
  {
    category: Category.CIF,
    label: 'CIF price at destination'
  },
  {
    category: Category.Export,
    label: 'Prices ex-Port'
  },
  {
    category: Category.DeliveredToBlendingFactory,
    label: 'Price delivered to the blending factory'
  },
  {
    category: Category.DeliveredAtImporterWarehouse,
    label: 'Price delivered at the importer warehouse'
  },
  {
    category: Category.ExImporterWarehouse,
    label: 'Prices ex importer warehouse'
  },
  {
    category: Category.DeliveredAtWholesalerWarehouse,
    label: 'Price delivered at wholesaler warehouse'
  },
  {
    category: Category.FinalPrices,
    label: 'Final Prices'
  }
]

export default function ResultsFilterSelector({ id, value, onChange }) {

  const panel = React.useRef()

  function itemTemplate(model) {
    const { category, label } = model;


    function toggleItem(flag) {
      if (flag) {
        onChange([ ...value, category ])
      } else {
        onChange(value.filter(it => it !== category))
      }
    }

    return (
      <div key={category}>
        <Checkbox
          checked={value.some(it => it === category)}
          onChange={e => toggleItem(e.checked)}
        />
        <span style={{ marginLeft: 2 }}>{label}</span>
      </div>
    )
  }

  return (
    <>
      <OverlayPanel ref={panel}>
        { model.map(itemTemplate) }
      </OverlayPanel>
      <Button id={id} label="Filter" style={{ width: '100%' }} onClick={e => panel.current?.toggle(e)}/>
    </>
  )
}
