import { combineReducers } from 'redux';

import currency from './currencyReducer';
import compare from './compareReducer';
import formula from './formulaReducer';
import ui from './uiReducer';

export default combineReducers({
    formula, ui, currency, compare
})
