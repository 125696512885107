import React from 'react';

import { auth, getUserDocument } from '../services/firebase';
import { NotificationContext } from './NotificationProvider';
import { OWN_FORMULAS } from '../data';

export const UserContext = React.createContext({ user: null })

export function UserProvider({ children }) {
  const [user, setUser] = React.useState()
  const notification = React.useContext(NotificationContext)

  async function onUserUpdated(newUser) {
    OWN_FORMULAS.forget()
    if (newUser) {

      // Remove null and undefined values from the object first level keyset
      // The reason for doing this is that this allows to create a priority mechanism
      // In using data provided by Firestore and by Firebase Auth.
      // Data that came form Firebase Auth has priority over Firestore
      const mutableUser = Object.fromEntries(
        Object.entries(newUser)
          .filter(([,value]) => value !== null && value !== undefined)
      )
      setUser({ ...await getUserDocument(newUser.uid), ...mutableUser })
      //auth.currentUser.getIdToken(true).then(console.log)
      if (window.location.pathname === "" || window.location.pathname === "/") {
        window.location.pathname = "/my"
      }
    } else {
      if (window.location.pathname !== "/") {
        window.location.pathname = "/"
      }
      notification.sendInfo("Logged Out")
      setUser(null)
    }
  }

  React.useEffect(() => void auth.onAuthStateChanged(onUserUpdated), [])

  return (
    <UserContext.Provider value={user}>
      { children }
    </UserContext.Provider>
  );
}
