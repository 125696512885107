import { Types } from '../actions';

const defaultState = Object.freeze({
  formulas: [],
  editingIndex: undefined
})

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case Types.COMP_ADD_FORMULA: return {
      ...state,
      formulas: [...state.formulas, payload]
    }

    case Types.COMP_RM_FORMULA: return {
      ...state,
      formulas: state.formulas.filter((_, index) => payload !== index)
    }

    case Types.COMP_UPDATE_PRODUCTS: return {
      ...state,
      formulas: state.formulas.map((formula, index) => {
        return index !== payload.index ? formula : {
          ...formula,
          products: payload.products
        }
      })
    }

    case Types.COMP_UPDATE_BLENDING: return {
      ...state,
      formulas: state.formulas.map((formula, index) => {
        return index !== payload.index ? formula : {
          ...formula,
          blending: payload.blending
        }
      })
    }

    case Types.COMP_UPDATE_DESTINATION: return {
      ...state,
      formulas: state.formulas.map((formula, index) => {
        return index !== payload.index ? formula : {
          ...formula,
          destination: payload.destination
        }
      })
    }

    case Types.COMP_EDIT_FORMULA: return {
      ...state,
      editingIndex: payload
    }

    case Types.COMP_CLEAR: return defaultState

    default: return state
  }
}
