import React from 'react';
import { NotificationContext } from '../providers/NotificationProvider';

export default (message, details) => {
  const notifications = React.useContext(NotificationContext)
  return (e) => {
    console.error(e)
    try {
      if (notifications) {
        notifications.sendError(message || 'An error occurred', details || 'Please try again later')
      }
    } catch (e2) {
      console.error(e2)
    }
  }
}
