import React from 'react';
import { Redirect } from 'react-router';
import useSearchParam from '../../../hooks/useSearchParam';
import { useDispatch } from 'react-redux';

import * as Actions from '../../../redux/actions';
import { firestore } from '../../../services/firebase';
import { useUrlFinalDestination } from '../../utils/selectors/url/UrlFinalDestinationSelector';
import { useUrlPlaceOfBlending } from '../../utils/selectors/url/UrlPlaceOfBlendingSelector';

export default function ShareUrlHandler() {

  const dispatch = useDispatch()
  const shareId = useSearchParam('id')

  const destination = useUrlFinalDestination()
  const blending = useUrlPlaceOfBlending()

  React.useEffect(() => {
    if (shareId) {
      firestore.collection("compareShares")
        .doc(shareId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data()
            data.formulas.forEach(formula => dispatch(Actions.compAddFormula(formula)))
          }
        })
    } else {
      dispatch(Actions.compClear())
    }
  }, [shareId])

  const query = React.useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);

    if (blending) {
      queryParams.set('blending', blending);
    }

    if (destination) {
      queryParams.set('destination', destination)
    }

    queryParams.set('origin', 'shared')
    queryParams.delete('id')

    return '?' + queryParams.toString()

  }, [destination, blending])

  return <Redirect to={`/my/formulas/compare${query}`}/>
}
