import { mkPair } from './minmax';
import { TransitMode } from '../const';

// F25:G25 of The Matrix sheet
// https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#charges-and-fees-at-port
export function getChargesAndFeesAtPort(portId, passageModeId, portCharges) {
  const charge = portCharges.find(it => it.mode.id === passageModeId && it.port.id === portId)

  return charge ? mkPair(charge.min, charge.max) : mkPair(0, 0)
}

// F36:G36 of The Matrix sheet
// https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minimummaximum-transport-to-inland
export function getTransportToInland(transitMode, placeOfBlendingId, portId, destinationId, landTransports) {

  if (transitMode === TransitMode.DIRECT) {
    function findFrom({ fromPort, fromPlaceOfBlending }) {
      if (placeOfBlendingId) {
        if (fromPlaceOfBlending) {
          return fromPlaceOfBlending.id === placeOfBlendingId
        }
      } else {
        if (fromPort) {
          return fromPort.id === portId
        }
      }
      return false
    }

    const transport = landTransports.find(it => findFrom(it) && it.to.id === destinationId)
    return transport ? transport.price : 0
  } else {
    return 0
  }
}

// F54:G54 of The Matrix sheet
// https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minimummaximum-transport-to-inland
export function getTransport(transitMode, placeOfBlendingId, portId, destinationId, landTransports) {
  function acceptPlaceOfBlending({ fromPlaceOfBlending: from, to }) {
    return from && from.id === placeOfBlendingId && to.id === destinationId
  }

  function acceptPort({ fromPort: from, to }) {
    return from && from.id === portId && to.id === destinationId
  }

  if (transitMode === TransitMode.DIRECT) {
    if (placeOfBlendingId) {
      const transport = landTransports.find(acceptPlaceOfBlending)
      return transport ? transport.price : 0
    }
  } else {
    if (portId) {
      const transport = landTransports.find(acceptPort)
      return transport ? transport.price : 0
    } else {
      const transport = landTransports.find(acceptPlaceOfBlending)
      return transport ? transport.price : 0
    }
  }

  return 0;
}
