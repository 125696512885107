import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import { useFinalDestinations } from '../../../hooks';
import ItemWithCountryFlag from '../ItemWithCountryFlag';
import {formatDestination, FormatOptions} from "../../../formatter";

export default ({ id, value, onChange, style = {}, allowClear = false }) => {
  const [destinations] = useFinalDestinations();

  const items = destinations.map(it => ({
    value: it.id,
    label: formatDestination(it, [FormatOptions.COUNTRY_ALPHA]),
    ...it
  })).sort((a, b) => a.label.localeCompare(b.label))

  function createItemTemplate(town) {
    return <ItemWithCountryFlag
      display={formatDestination(town, [FormatOptions.COUNTRY_ALPHA])}
      alphaCode={town?.town?.country?.alphaCode}
      countryDisplayName={town?.town?.country?.displayName}
    />
  }

    return (
        <Dropdown
            id={id}
            value={value}
            options={items}
            appendTo={document.body}
            placeholder="Select a destination"
            itemTemplate={createItemTemplate}
            onChange={({ value }) => onChange(value)}
            style={{ minWidth: '18rem', width: '100%', ...style }}
            showClear={allowClear}
            filterBy="label"
            filter
        />
    )
}
