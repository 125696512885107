import React, { useMemo } from 'react';
import FormulaPicker from './FormulaPicker';
import { sumResultsApplyingPercentage } from '../../../calculation/results-utils';
import { Category, ResultsTable } from '../../ResultsTable';
import { areProductArrayEquals, useDeepCompareMemoized } from '../../../utils';
import ResultsFilterSelector from '../../utils/selectors/ResultsFilterSelector';
import { useSelector } from 'react-redux';
import { byId, queryRemoteFormulas } from '../../../hooks/queryRemoteFormulas';
import useFormulaResults from '../../../hooks/useFormulaResults';
import CurrencyEditor from '../../utils/CurrencyEditor';
import { Checkbox } from 'primereact/checkbox';
import { FloatActionButtons } from '../../CommonStyles.module.scss';
import { Button as FloatingButton } from 'react-floating-action-button';
import {
  UrlFinalDestinationSelector,
  useUrlFinalDestination,
} from '../../utils/selectors/url/UrlFinalDestinationSelector';
import {
  UrlPlaceOfBlendingSelector,
  useUrlPlaceOfBlending,
} from '../../utils/selectors/url/UrlPlaceOfBlendingSelector';
import { startEdit, TemporaryEditor } from './TemporaryEditor';
import { useFinalDestinations, usePlacesOfBlending } from '../../../hooks';
import ShareDialog from './ShareDialog';
import { FormatOptions, formatTown, formatDestination as formatDestinationName } from "../../../formatter";
import useSearchParam from "../../../hooks/useSearchParam";

export default function CompareFormulas() {

  const [placesOfBlending] = usePlacesOfBlending()
  const [destinations] = useFinalDestinations()

  const destinationId = useUrlFinalDestination()
  const placeOfBlendingId = useUrlPlaceOfBlending()

  const [sharing, setSharing] = React.useState(false)
  const [showDetails, setShowDetails] = React.useState(false)
  const [filter, setFilter] = React.useState(Object.values(Category))


  const formulasToCalculate = useSelector(state => state.compare.formulas)
  const rawResults = useFormulaResults(formulasToCalculate, destinationId, placeOfBlendingId)

  const origin = useSearchParam('origin')
  const shared = origin === 'shared'
  const remoteImmutableFormulas = shared ? formulasToCalculate : queryRemoteFormulas(formulasToCalculate.map((it) => byId(it.id)), true)

  const formatPlaceOfBlending = (id) => {
    const place = placesOfBlending.find(it => it.id === (id || placeOfBlendingId))
    return place ? formatTown(place, [FormatOptions.COUNTRY_ALPHA]) : 'Unknown';
  }

  const formatDestination = (id) => {
    const place = destinations.find(it => it.id === (id || destinationId))
    return place ? formatDestinationName(place, [FormatOptions.COUNTRY_ALPHA]) : 'Unknown';
  }

  const resultsDependency = useDeepCompareMemoized(rawResults)
  const results = useMemo(() => {
    return rawResults.map(it => ({
      blending: formatPlaceOfBlending(it.formula.blending),
      destination: formatDestination(it.formula.destination),
      ...sumResultsApplyingPercentage(it.results.map(innerIt => innerIt.MAX))
    })
  )}, [resultsDependency])

  const headers = rawResults.map((it, index) => (
      <span className="p-overlay-badge" >
        <span>
          <span>{ it.formula.name }</span>
          {
            (
              (it.formula.blending && it.formula.blending !== placeOfBlendingId)
              || (it.formula.destination && it.formula.destination !== destinationId)
              || !areProductArrayEquals(
                it.formula.products,
                remoteImmutableFormulas.find(remoteFormula => remoteFormula.id === it.formula.id)?.products
              )
            ) && <span title="Has Modifications">*</span>
          }
        </span>
        <span
          className="p-badge"
          title="Edit"
          onClick={() => startEdit(index)}
          style={{ minWidth: 1, height: 16, width: 16, cursor: 'pointer' }}
        >
          <i className="fa fa-pencil-alt" style={{ fontSize: '.65rem', marginBottom: 4, transform: 'translateY(-4px)' }}/>
        </span>
      </span>
    )
  )

  function renderTable() {
    return (
      <ResultsTable
        filter={filter}
        headers={headers}
        headersTitle="Formula *"
        data={results}
        showDetails={showDetails}
        styles={{ paddingTop: 16 }}
      />
    )
  }

  return (
    <>
      <ShareDialog visible={sharing} setVisible={setSharing}/>
      <div className="p-grid">
        <div className="p-col p-sm-12 p-md-2">
          <label htmlFor="formula" style={{ fontWeight: 'bold', fontSize: '.75rem' }}>Formulas:</label>
          <br/>
          <FormulaPicker id="formula"/>
        </div>
        <div className="p-col p-sm-12 p-md-2">
          <label style={{ fontWeight: 'bold', fontSize: '.75rem'}} htmlFor="placeOfBlending">Default Place of Blending/Bagging:</label>
          <br/>
          <UrlPlaceOfBlendingSelector id="placeOfBlending" allowNone={false}/>
        </div>
        <div className="p-col p-sm-12 p-md-4">
          <label htmlFor="destination" style={{ fontWeight: 'bold', fontSize: '.75rem' }}>Default Final Destination:</label>
          <br/>
          <UrlFinalDestinationSelector id="destination"/>
        </div>
        <div className="p-col p-md-2">
          <label htmlFor="destination" style={{ fontWeight: 'bold', fontSize: '.75rem' }}>Filter:</label>
          <br/>
          <ResultsFilterSelector id="filter" value={filter} onChange={setFilter} />
          <br/>
          <label>
            Show Details:
            <Checkbox style={{ marginLeft: 2 }} checked={showDetails} onChange={e => setShowDetails(e.checked)}/>
          </label>
        </div>
        <div className="p-col p-md-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CurrencyEditor/>
        </div>
      </div>
      <TemporaryEditor/>
      {
        formulasToCalculate.length ? placeOfBlendingId ? destinationId ? renderTable() :  'Please select a default final destination' : 'Please select a default place of blending/bagging' : 'Please select at least one formula'
      }
      <div className={FloatActionButtons}>
        <div>
          <FloatingButton
            tooltip="Print"
            icon="fa fa-print"
            onClick={() => window.print()}
          />
        </div>
        <div>
          <FloatingButton
            tooltip="Share"
            icon="fa fa-share-alt"
            onClick={() => setSharing(true)}
          />
        </div>
      </div>
      </>
  )
}
