import React from 'react';
import { ApiContext } from '../../../../providers/ApiProvider';
import { NotificationContext } from '../../../../providers/NotificationProvider';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import CommonStyles from '../../../CommonStyles.module.scss';
import { Dialog } from 'primereact/dialog';
import GenericMaintenanceScreen from './GenericMaintanceScreen';
import FloatLabel from '../../../utils/FloatLabel';
import useErrorHandler from '../../../../hooks/useErrorHandler';

function EditDialog({ id, onHide, visible, notify }) {
  const api = React.useContext(ApiContext)
  const notification = React.useContext(NotificationContext)

  const [loading, setLoading] = React.useState(false)
  const [triedOnce, setTriedOnce] = React.useState(false)

  const [code, setCode] = React.useState(id)
  const [name, setName] = React.useState('')

  const handleError = useErrorHandler('Something went wrong on managing the data')

  React.useEffect(refresh, [id])

  function refresh() {
    setTriedOnce(false)
    setLoading(true)
    if (id) {
      api.getCountry(id)
        .then(({ data }) => {
          setCode(data.alphaCode)
          setName(data.displayName)
          setLoading(false)
        }).catch(handleError)
    } else {
      setCode('')
      setName('')
      setLoading(false)
    }
  }

  function finish() {
    notification.sendSuccess('Country saved successfully', name)
    onHide()
    notify()
  }

  function send() {
    setTriedOnce(true)
    if (name) {
      const payload = { name }
      setLoading(true)
      if (id) {
        api.editCountry(id, payload).then(finish).catch(handleError)
      } else {
        api.addCountry({ code, ...payload }).then(finish).catch(handleError)
      }
    }
  }

  function askDelete() {
    if (window.confirm(`Are you sure you want to delete ${name} (${id})?\nThis is an one-way action!`)) {
      api.deleteCountry(id).then(finish).catch(handleError)
    }
  }

  const footer = <>
    { id && !loading && <Button label="Delete" icon="fa fa-trash-alt" className="p-button-danger" onClick={askDelete}/> }
    { !loading && <Button label="Save" icon="fa fa-save" onClick={send}/> }
    <Button label="Cancel" className="p-button-secondary" onClick={onHide}/>
  </>

  const body = <>
    { !id && (
      <FloatLabel id="alphaCode" label="Alpha Code (2 or 3 digits)">
        <InputText maxLength={3} value={code} onChange={({ target }) => setCode(target.value.toUpperCase())}></InputText>
      </FloatLabel>
    )}
    <FloatLabel id="name" label="Name">
      <InputText value={name} onChange={({ target }) => setName(target.value)}/>
    </FloatLabel>
    { triedOnce && !name && <span className={CommonStyles.RedText}>Please provide a name</span> }
  </>

  return (
    <Dialog
      onHide={onHide}
      visible={visible}
      header={id ? `Editing ${name} (${id})` : 'Creating'}
      footer={footer}
    >
      { loading ? <ProgressSpinner className={CommonStyles.CenterSpinner}/> : body }
    </Dialog>
  )
}

export default function CountryMaintenance() {

  const api = React.useContext(ApiContext)

  const dialog = ({ id, onHide, visible, notify }) => (
    <EditDialog id={id} onHide={onHide} visible={visible} notify={notify} />
  )

  const provider = async () => {
    const countries = (await api.getCountries()).data
    return { data: countries.map(({ alphaCode, displayName }) => ({ id: alphaCode, displayName })) }
  }

  return (
    <GenericMaintenanceScreen
      dataProvider={provider}
      renderEditDialog={dialog}
    >
      <Column field="id" header="Alpha Code" sortable/>
      <Column field="displayName" header="Name" sortable/>
    </GenericMaintenanceScreen>
  )
}
