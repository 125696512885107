import { MinMax, mkPair } from './minmax';
import FormulaCalculation from './FormulaCalculation';

export default class CalculationDescription {

  constructor(products, destination, placeOfBlendingId, landTransports, portCharges) {
    this.products = products
    this.destination = destination
    this.placeOfBlendingId = placeOfBlendingId
    this.landTransports = landTransports
    this.portCharges = portCharges
    this.recalculate()
  }

  recalculate() {
    this.results = this.products.map((product) => mkPair(
      new FormulaCalculation(product, MinMax.MIN, this),
      new FormulaCalculation(product, MinMax.MAX, this),
    ))
  }
}
