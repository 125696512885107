import React from 'react';

import { Card } from 'primereact/card';
import { useSelector } from 'react-redux';
import { Body } from './Body';
import { Header } from './Header';

export function ProductCard({ index }) {

  const data = useSelector(state => state.formula.products[index])

  const header = <Header data={data} index={index}/>

  return (
    <Card header={header}>
      <Body data={data} />
    </Card>
  )
}
