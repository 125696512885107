import lazy from 'lazyasfk';
import { MinMax } from './minmax';
import {
  COST_PROCESS,
  CUSTOM_LAND_BORDER,
  ECOWAS_AU,
  FACTORY_DEPRECIATION,
  FORWARDER_FEES,
  FORWARDER_LAND_BORDER,
  GARANTY_FOUND,
  IMPORTER_ADMINISTRATIVE_FEES,
  IMPORTER_BANK_FEES,
  IMPORTER_HANDLING_FEES,
  IMPORTER_INSURRANCE,
  IMPORTER_LOSS_OF_WEIGHT,
  IMPORTER_RAW_MARGIN,
  IMPORTER_TAXES_ON_MARGIN,
  OTHER_SMALL_TRANSIT_FEES,
  PORT_TO_WAREHOUSE,
  SEA_FREIGHT_INSURRANCE,
  WHOLESALER_HANDLING_FEES,
  WHOLESALER_LOSS_OF_WEIGHT,
  WHOLESALER_RAW_MARGIN,
  WHOLESALER_STORAGE_FEES,
} from './formula-calculation-constants';
import { TransitMode } from '../const';
import {
  getChargesAndFeesAtPort,
  getTransport,
  getTransportToInland,
} from './productExtras';

export default class FormulaCalculation {

  constructor(product, mode, description) {
    this.product = product
    this.mode = mode

    this.fobPriceAtOrigin = mode === MinMax.MIN ? product.minFob : product.maxFob
    this.seaFreight = product.seaFreight
    this.demurrage = product.demurrageDuration * product.demurragePrice

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minimum-sea-freight-insurrance
    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#maximum-sea-freight-insurrance
    this.seaFreightInsurrance = !product.port ? 0 : this.fobPriceAtOrigin * SEA_FREIGHT_INSURRANCE.get(mode)

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minimum-cif-price-at-destination
    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#maximum-cif-price-at-destinantion
    this.cifPriceDestination = this.fobPriceAtOrigin + this.seaFreight + this.seaFreightInsurrance

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#charges-and-fees-at-port
    this.chargesAndFeesAtPort = getChargesAndFeesAtPort(
      product.port,
      product.passageMode,
      description.portCharges
    ).get(this.mode)

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minimum-forwarder-fees-port
    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#maximum-forwarder-fees-port
    this.portForwarderFees = !product.port ? 0 : FORWARDER_FEES.get(mode) * this.cifPriceDestination

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-custom-levy
    this.customLevy = (this.cifPriceDestination * (this.product.type.customTarrif / 100)) || 0

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minimum-custom-charges-ecowas--au
    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#maximum-custom-charges-ecowas--au
    this.ecowasAu = !product.port ? 0 : ECOWAS_AU * this.cifPriceDestination

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minimum-statistics-levy
    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#maximum-statistics-levy
    this.statisticLevy = !product.port ? 0 : !description.destination ? 0 : (description.destination.statisticDuty / 100) * this.cifPriceDestination

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minimum-solidarity-levy
    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#maximum-solidarity-levy
    this.solidarityLevy = !product.port ? 0 : (description.destination.solidarityLevy / 100) * this.cifPriceDestination

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minimum-ccvr-levy
    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#maximum-ccvr-levy
    this.ccvrLevy = !product.port ? 0 : (description.destination.ccvrLevy / 100) * this.cifPriceDestination

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minimum-exim-levy
    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#maximum-exim-levy
    this.eximLevy = !product.port ? 0 : (description.destination.eximLevy / 100) * this.cifPriceDestination

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minimummaximum-prices-ex-port
    this.pricesExport = this.cifPriceDestination
      + this.chargesAndFeesAtPort
      + this.portForwarderFees
      + this.demurrage
      + this.customLevy
      + this.ecowasAu
      + this.statisticLevy
      + this.solidarityLevy
      + this.ccvrLevy
      + this.eximLevy

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minimummaximum-garanty-found-fgr
    this.garantyFound = product.transit !== TransitMode.DIRECT ? 0 : (GARANTY_FOUND / 100) * this.cifPriceDestination

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minimummaximum-transport-to-inland
    this.transportToInland = getTransportToInland(
      product.transit,
      description.placeOfBlendingId,
      product.port,
      description.destination.id,
      description.landTransports
    )

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minimummaximum-other-small-transit-fees
    this.otherSmallTransitFees = product.transit !== TransitMode.DIRECT ? 0 : (OTHER_SMALL_TRANSIT_FEES / 100) * this.cifPriceDestination

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-port-to-warehouse
    this.portToWarehouse = !product.transit || product.transit === TransitMode.DIRECT ? 0 : PORT_TO_WAREHOUSE.get(this.mode)

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minimummaximum-price-delivered-to-the-blending-factory
    this.priceDeliveredToBlendingFactory = this.pricesExport
      + this.garantyFound
      + this.transportToInland
      + this.otherSmallTransitFees
      + this.portToWarehouse

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#cost-ingredients
    this.costIngredients = this.priceDeliveredToBlendingFactory * (product.percentage / 100)

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#cost-process
    this.costProccess = !description.placeOfBlendingId ? 0 : COST_PROCESS.get(this.mode)

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#factory-depreciation
    this.factoryDepreciation = !description.placeOfBlendingId ? 0 : FACTORY_DEPRECIATION.get(this.mode)

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#price-delivered-at-the-importer-warehouse
    this.priceDeliveredAtImporterWarehouse = lazy(() => {
      return !description.placeOfBlendingId
        ? this.costIngredients
        : description.results
          .filter(it => it.get(mode).product.percentage <= 100)
          .reduce((acc, it) => acc + it.get(mode).costIngredients, 0)
            + (description.products.indexOf(product) === 0 ? this.costProccess : 0)
            + (description.products.indexOf(product) === 0 ? this.factoryDepreciation : 0)
    })

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-bank-fees
    this.importerBankFees = lazy(() => {
      return this.priceDeliveredAtImporterWarehouse.get() * (IMPORTER_BANK_FEES.get(mode) / 100)
    })

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-bank-interest
    this.importerBankInterest = lazy(() => {
      return ((product.loanCashoutDuration * product.bankInterestRate) / (12 * this.priceDeliveredAtImporterWarehouse.get())) || 0
    })

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-storage-fees
    this.importerStorageFees = product.storageDuration * product.storageCost

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-importer-handling-fees
    this.importerHandlingFees = lazy(() => {
      return !product ? 0 : IMPORTER_HANDLING_FEES.get(this.mode)
    })

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-importer-administrative-fees
    this.importerAdministrativeFees = !product ? 0 : IMPORTER_ADMINISTRATIVE_FEES.get(this.mode)

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-importer-insurrance
    this.importerInsurrance = lazy(() => {
      return this.priceDeliveredAtImporterWarehouse.get() * (IMPORTER_INSURRANCE.get(this.mode) / 100)
    })

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-loss-of-weight
    this.importerLossOfWeight = lazy(() => {
      return this.priceDeliveredAtImporterWarehouse.get() * (IMPORTER_LOSS_OF_WEIGHT.get(this.mode) / 100)
    })

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-importer-raw-margin
    this.importerRawMargin = !product ? 0 : IMPORTER_RAW_MARGIN.get(this.mode)

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-importer-taxes-on-margin
    this.importerTaxesOnMargin = this.importerRawMargin * (IMPORTER_TAXES_ON_MARGIN / 100)

    // TODO
    this.pricesExImportWarehouse = lazy(() => {
      return this.priceDeliveredAtImporterWarehouse.get()
        + this.importerBankFees.get()
        + this.importerBankInterest.get()
        + this.importerStorageFees
        + this.importerHandlingFees.get()
        + this.importerAdministrativeFees
        + this.importerInsurrance.get()
        + this.importerLossOfWeight.get()
        + this.importerRawMargin
        + this.importerTaxesOnMargin
    })

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#transport
    this.transport = getTransport(
      product.transit,
      description.placeOfBlendingId,
      product.port,
      description.destination.id,
      description.landTransports
    )

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-custom-land-border
    this.customLandBorder = lazy(() => {
      return this.product.transit !== TransitMode.INDIRECT ? 0 : this.pricesExImportWarehouse.get() * (CUSTOM_LAND_BORDER / 100)
    })

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-forwarder-land-border
    this.forwardingLandBorder = this.product.transit !== TransitMode.INDIRECT ? 0 : FORWARDER_LAND_BORDER.get(this.mode)

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-price-delivered-at-wholesale-warehouse
    this.priceDeliveredAtWholesalerWarehouse = lazy(() => {
      return this.pricesExImportWarehouse.get()
        + this.transport
        + this.customLandBorder.get()
        + this.forwardingLandBorder
    })

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-wholesaler-storage-fees
    this.wholesalerStorageFees = WHOLESALER_STORAGE_FEES.get(this.mode)

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-wholesaler-handling-fees
    this.wholesalerHandlingFees = WHOLESALER_HANDLING_FEES.get(this.mode)

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-wholesaler-loss-of-weight
    this.wholesalerLossOfWeight = lazy(() => {
      return this.priceDeliveredAtWholesalerWarehouse.get() * (WHOLESALER_LOSS_OF_WEIGHT.get(this.mode) / 100)
    })

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-wholesaler-raw-margin
    this.wholesalerRawMargin = WHOLESALER_RAW_MARGIN.get(this.mode)

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-wholesaler-taxes-on-margin
    this.wholesalerTaxesOnMargin = this.wholesalerRawMargin * 0.05

    // https://github.com/IFDC/Fertilizer-Cost-Calculator/wiki/Formulas#minmax-wholesale-prices-production-area
    this.wholesalePricesProductionArea = lazy(() => {
      return this.priceDeliveredAtWholesalerWarehouse.get()
        + this.wholesalerStorageFees
        + this.wholesalerHandlingFees
        + this.wholesalerLossOfWeight.get()
        + this.wholesalerRawMargin
        + this.wholesalerTaxesOnMargin
    })
  }
}
