import React from 'react';

import { useDispatch } from 'react-redux';
import { FormulaOverview } from './FormulaOverview';

import * as Actions from '../../../redux/actions';
import { byId, queryRemoteFormulas } from '../../../hooks/queryRemoteFormulas';
import { useDeepCompareMemoized } from '../../../utils';

export function FormulasPage({ match }) {

  const dispatch = useDispatch()
  const selectedFormula = match?.params?.formulaId

  const formulaData = queryRemoteFormulas([ byId(selectedFormula) ])

  React.useEffect(() => {
    dispatch(Actions.clearFormula())
    dispatch(Actions.createProduct())
  }, [selectedFormula])

  React.useEffect(() => {
    if (formulaData) {
      const { id, name, products, public: isPublic } = formulaData;
      dispatch(Actions.loadFormula(id, name, products, isPublic))
    }
  }, [useDeepCompareMemoized(formulaData)]);

  return <FormulaOverview/>
}
