import React from 'react';
import { ApiContext } from '../../../../providers/ApiProvider';
import { NotificationContext } from '../../../../providers/NotificationProvider';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import CommonStyles from '../../../CommonStyles.module.scss';
import { Dialog } from 'primereact/dialog';
import GenericMaintenanceScreen from './GenericMaintanceScreen';
import FloatLabel from '../../../utils/FloatLabel';
import CountrySelector from '../../../utils/selectors/CountrySelector';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import { Checkbox } from 'primereact/checkbox';

function EditDialog({ id, onHide, visible, notify }) {
  const api = React.useContext(ApiContext)
  const notification = React.useContext(NotificationContext)
  const errorHandler = useErrorHandler('Something went wrong on managing the data')

  const [loading, setLoading] = React.useState(false)
  const [triedOnce, setTriedOnce] = React.useState(false)

  const [name, setName] = React.useState('')
  const [country, setCountry] = React.useState()
  const [isPlaceOfBlending, setPlaceOfBlending] = React.useState(false)

  React.useEffect(refresh, [id])

  function refresh() {
    setTriedOnce(false)
    setLoading(true)
    if (id) {
      api.getTown(id)
        .then(({ data }) => {
          setName(data.name)
          setCountry(data.country.alphaCode)
          setPlaceOfBlending(data.isPlaceOfBlending)
          setLoading(false)
        }).catch(errorHandler)
    } else {
      setName('')
      setCountry(undefined)
      setLoading(false)
      setPlaceOfBlending(false)
    }
  }

  function finish() {
    notification.sendSuccess('Town saved successfully', name)
    onHide()
    notify()
  }

  function send() {
    setTriedOnce(true)
    if (name && country) {
      const payload = { name, country, isPlaceOfBlending }
      setLoading(true)
      if (id) {
        api.editTown(id, payload).then(finish).catch(errorHandler)
      } else {
        api.addTown(payload).then(finish).catch(errorHandler)
      }
    }
  }

  function askDelete() {
    if (window.confirm(`Are you sure you want to delete ${name}?\nThis is an one-way action!`)) {
      api.deleteTown(id).then(finish).catch(useErrorHandler)
    }
  }

  const footer = <>
    { id && !loading && <Button label="Delete" icon="fa fa-trash-alt" className="p-button-danger" onClick={askDelete}/> }
    { !loading && <Button label="Save" icon="fa fa-save" onClick={send}/> }
    <Button label="Cancel" className="p-button-secondary" onClick={onHide}/>
  </>

  const body = <>
    <FloatLabel id="name" label="Name">
      <InputText value={name} onChange={({ target }) => setName(target.value)}/>
    </FloatLabel>
    { triedOnce && !name && <span className={CommonStyles.RedText}>Please provide a name</span> }
    <div style={{ marginTop: 8, marginBottom: 8 }}>
      <label htmlFor="country">Country:</label><br/>
      <CountrySelector
        id="country"
        value={country}
        onChange={setCountry}
      />
    </div>
    <div>
      <label htmlFor="isPlaceOfBlending">Is Place of Blending:</label>
      <Checkbox
        id="isPlaceOfBlending"
        checked={isPlaceOfBlending}
        onChange={(e) => setPlaceOfBlending(e.checked)}
      />
    </div>
  </>

  return (
    <Dialog
      onHide={onHide}
      visible={visible}
      header={id ? `Editing ${name}` : 'Creating'}
      footer={footer}
    >
      { loading ? <ProgressSpinner className={CommonStyles.CenterSpinner}/> : body }
    </Dialog>
  )
}

export default function TownMaintenance() {

  const api = React.useContext(ApiContext)

  const dialog = ({ id, onHide, visible, notify }) => (
    <EditDialog id={id} onHide={onHide} visible={visible} notify={notify} />
  )

  return (
    <GenericMaintenanceScreen
      dataProvider={api.getTowns}
      renderEditDialog={dialog}
    >
      <Column field="id" header="Id" sortable/>
      <Column field="name" header="Name" sortable/>
      <Column
        field="isPlaceOfBlending"
        header="Place of Blending"
        body={({ isPlaceOfBlending }) => isPlaceOfBlending ? 'Yes' : 'No'}
        sortable
      />
      <Column
        field="country"
        header="Country"
        body={({ country }) => `${country.displayName} (${country.alphaCode})`}
        sortable
      />
    </GenericMaintenanceScreen>
  )
}
