import React from 'react';

import Styles from './ResultsTable.module.scss';
import { useSelector } from 'react-redux';
import { orGet } from '../calculation/results-utils';

export const Category = {
  CIF: 0,
  Export: 1,
  DeliveredToBlendingFactory: 2,
  DeliveredAtImporterWarehouse: 3,
  ExImporterWarehouse: 4,
  DeliveredAtWholesalerWarehouse: 5,
  FinalPrices: 6
}

export function ResultsTable({ data, filter, headers, headersTitle, showDetails, styles }) {

  function CategoryRenderer({ category, children }) {
    return filter.length === 0 || filter.some(it => it === category) ? children : null
  }

  const fcfa = useSelector(state => state.currency.fcfa)
  const ghs = useSelector(state => state.currency.ghs)

  function extractValue(selector, bold) {
    function select(it) {
      const selected = selector(it)
      if (selected === undefined || (isNaN(selected) && typeof selected !== 'string')) {
        return undefined
      } else {
        return typeof selected === 'number' ? selected.toFixed(2) : selected
      }
    }
    return data.map((it, index) => {
      const selectedValue = select(it)
      if (selectedValue === undefined) {
        return undefined
      } else {
        return bold ? <th key={index} style={{ minWidth: '8rem' }}>{select(it)}</th> : <td style={{ minWidth: '8rem' }} key={index}>{select(it)}</td>
      }
    })
  }

  function details(label, selectValue) {
    return !showDetails ? null : (
      <tr className={Styles.Details}>
        <td>{label}</td>
        { extractValue(selectValue) }
      </tr>
    )
  }

  const longestHeader = headers.reduce((acc, it) => Math.max(acc, it.length), 0)

  return (
    <div style={styles} className={Styles.TableWrapper}>
      <span className={Styles.Table}>* All values in USD/MT otherwise stated</span>
      <table className={Styles.Table}>
        <thead>
        <tr>
          <th style={{ minWidth: `${longestHeader * 1.25}rem` }}>{headersTitle}</th>
          { headers.map((title, index) => <th key={index}>{title}</th>) }
        </tr>
        </thead>
        <tbody>

        { data.some(it => it.blending || it.destination) && (
          <>
            <tr>
              <td>Blending</td>
              { extractValue(it => it.blending) }
            </tr>
            <tr style={{ verticalAlign: 'text-top' }}>
              <td style={{ height: '1.75rem' }}>Destination</td>
              { extractValue(it => it.destination) }
            </tr>
          </>
        ) }

        <CategoryRenderer category={Category.CIF}>
          <tr>
            <th>FOB Price at Origin</th>
            { extractValue(it => orGet(it.fobPriceAtOrigin), true) }
          </tr>
        </CategoryRenderer>
        <CategoryRenderer category={Category.CIF}>
          <tr>
            <td>Sea Freight</td>
            { extractValue(it => orGet(it.seaFreight) + orGet(it.seaFreightInsurrance), false) }
          </tr>

          { details('Sea Freight', it => orGet(it.seaFreight)) }
          { details('Insurrance', it => orGet(it.seaFreightInsurrance)) }

          <tr>
            <th>CIF Price at Destination</th>
            { extractValue(it => orGet(it.cifPriceDestination), true) }
          </tr>
        </CategoryRenderer>
        <CategoryRenderer category={Category.Export}>
          <tr>
            <td>Port Charges</td>
            { extractValue(it =>
              orGet(it.chargesAndFeesAtPort)
              + orGet(it.portForwarderFees)
              + orGet(it.demurrage)
            )}
          </tr>

          { details('Charges and Fees at Port', it => orGet(it.chargesAndFeesAtPort)) }
          { details('Forwarder Fees (port)', it => orGet(it.portForwarderFees)) }
          { details('Demurrage', it => orGet(it.demurrage)) }

          <tr>
            <td>Custom Charges</td>
            {
              extractValue(it => {
                return orGet(it.customLevy)
                  + orGet(it.ecowasAu)
                  + orGet(it.statisticLevy)
                  + orGet(it.solidarityLevy)
                  + orGet(it.ccvrLevy)
                  + orGet(it.eximLevy)
              }, false)
            }
          </tr>

          { details('Custom Levy', it => orGet(it.customLevy)) }
          { details('ECOWAS + Afrian Union', it => orGet(it.ecowasAu)) }
          { details('Statistics Levy', it => orGet(it.statisticLevy)) }
          { details('Solidarity Levy', it => orGet(it.solidarityLevy)) }
          { details('CCVR Levy', it => orGet(it.ccvrLevy)) }
          { details('Exim Levy', it => orGet(it.eximLevy)) }

          <tr>
            <th>Prices Ex-port</th>
            { extractValue(it => orGet(it.pricesExport), true) }
          </tr>
        </CategoryRenderer>
        <CategoryRenderer category={Category.DeliveredToBlendingFactory}>
          <tr>
            <td>Direct Transit</td>
            {
              extractValue(it => orGet(it.garantyFound)
                + orGet(it.transportToInland)
                + orGet(it.otherSmallTransitFees)
                , false)
            }
          </tr>

          { details('Garanty Found (FGR)', it => orGet(it.garantyFound)) }
          { details('Transport to Inland', it => orGet(it.transportToInland)) }
          { details('Other Small Transit Fees', it => orGet(it.otherSmallTransitFees)) }

          <tr>
            <td>Port to Warehouse</td>
            { extractValue(it => orGet(it.portToWarehouse)) }
          </tr>
          <tr>
            <th>Price Delivered to the blending factory</th>
            { extractValue(it => orGet(it.priceDeliveredToBlendingFactory), true) }
          </tr>
        </CategoryRenderer>
        <CategoryRenderer category={Category.DeliveredAtImporterWarehouse}>

          { details('Cost Ingredients', it => orGet(it.costIngredients)) }
          { details('Cost Process', it => orGet(it.costProccess)) }
          { details('Factory Depreciation', it => orGet(it.factoryDepreciation)) }

          <tr>
            <th>Price Ex Work (FOT)</th>
            { extractValue(it => orGet(it.priceDeliveredAtImporterWarehouse), true) }
          </tr>
        </CategoryRenderer>
        <CategoryRenderer category={Category.ExImporterWarehouse}>
          <tr>
            <td>Overheads Importer</td>
            {
              extractValue(it => {
                return (
                  orGet(it.importerBankFees)
                  + orGet(it.importerBankInterest)
                  + orGet(it.importerStorageFees)
                  + orGet(it.importerHandlingFees)
                  + orGet(it.importerAdministrativeFees)
                  + orGet(it.importerInsurrance)
                  + orGet(it.importerLossOfWeight)
                  + orGet(it.importerRawMargin)
                  + orGet(it.importerTaxesOnMargin)
                )
              }, false)
            }
          </tr>

          { details('Bank Fees', it => orGet(it.importerBankFees)) }
          { details('Bank Interest', it => orGet(it.importerBankInterest)) }
          { details('Storage Fees', it => orGet(it.importerStorageFees)) }
          { details('Handling Fees', it => orGet(it.importerHandlingFees)) }
          { details('Administrative Fees', it => orGet(it.importerAdministrativeFees)) }
          { details('Insurrance', it => orGet(it.importerInsurrance)) }
          { details('Loss of Weight', it => orGet(it.importerLossOfWeight)) }
          { details('Raw Margin', it => orGet(it.importerRawMargin)) }
          { details('Taxes on Margin', it => orGet(it.importerTaxesOnMargin)) }

          <tr>
            <th>Prices ex importer warehouse</th>
            { extractValue(it => orGet(it.pricesExImportWarehouse), true) }
          </tr>
        </CategoryRenderer>
        <CategoryRenderer category={Category.DeliveredAtWholesalerWarehouse}>
          <tr>
            <td>Transport to Production Area</td>
            { extractValue(it => orGet(it.transport)
              + orGet(it.customLandBorder)
              + orGet(it.forwardingLandBorder)
            ) }
          </tr>

          { details('Transport', it => orGet(it.transport)) }
          { details('Custom Land Border', it => orGet(it.customLandBorder)) }
          { details('Forwarding Land Border', it => orGet(it.forwardingLandBorder)) }

          <tr>
            <th>Price Delivered at the wholesaler warehouse</th>
            { extractValue(it => orGet(it.priceDeliveredAtWholesalerWarehouse), true) }
          </tr>
        </CategoryRenderer>
        <CategoryRenderer category={Category.FinalPrices}>
          <tr>
            <td>Overheads Wholesaler</td>
            {
              extractValue(it => {
                return orGet(it.wholesalerStorageFees)
                  + orGet(it.wholesalerHandlingFees)
                  + orGet(it.wholesalerLossOfWeight)
                  + orGet(it.wholesalerRawMargin)
                  + orGet(it.wholesalerTaxesOnMargin)
              }, false)
            }
          </tr>

          { details('Storage Fees', it => orGet(it.wholesalerStorageFees)) }
          { details('Handling Fees', it => orGet(it.wholesalerHandlingFees)) }
          { details('Loss of Weight', it => orGet(it.wholesalerLossOfWeight)) }
          { details('Raw Margin', it => orGet(it.wholesalerRawMargin)) }
          { details('Taxes on Margin', it => orGet(it.wholesalerTaxesOnMargin)) }

          <tr className={`${Styles.FinalPricesMain} ${Styles.FinalPrices}`}>
            <th>Wholesale prices production area</th>
            { extractValue(it => orGet(it.wholesalePricesProductionArea), true) }
          </tr>

          <tr style={{ height: '1rem' }}/>

          <tr className={Styles.FinalPrices}>
            <td>Wholesale prices production area (USD/50kg bag)</td>
            { extractValue(it => orGet(it.wholesalePricesProductionArea) / 20) }
          </tr>
          <tr className={Styles.FinalPrices}>
            <td>Wholesale prices production area (FCFA/50kg bag)</td>
            { extractValue(it => orGet(it.wholesalePricesProductionArea) * (fcfa / 20)) }
          </tr>
          <tr className={Styles.FinalPrices}>
            <td>Wholesale prices production area (GH₵/50kg bag)</td>
            { extractValue(it => orGet(it.wholesalePricesProductionArea) * (ghs / 20)) }
          </tr>
        </CategoryRenderer>
        </tbody>
      </table>
    </div>
  )

}
