import React from 'react';

import { useSelector } from 'react-redux';
import useFormulaResults from './useFormulaResults';

export default (destinationId, placeOfBlendingId) => {
  const formula = useSelector(state => state.formula)
  const results = useFormulaResults([formula], destinationId, placeOfBlendingId)

  return results.length ? results[0] : undefined
}
