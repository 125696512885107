import React from 'react';
import { ApiContext } from '../../../../providers/ApiProvider';
import { NotificationContext } from '../../../../providers/NotificationProvider';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';

import CommonStyles from '../../../CommonStyles.module.scss';
import { Dialog } from 'primereact/dialog';
import GenericMaintenanceScreen from './GenericMaintanceScreen';
import FloatLabel from '../../../utils/FloatLabel';
import useTowns from '../../../../hooks/useTowns';
import TownSelector from '../../../utils/selectors/TownSelector';

function EditDialog({ id, onHide, visible, notify }) {
  const api = React.useContext(ApiContext)
  const notification = React.useContext(NotificationContext)

  const [loading, setLoading] = React.useState(false)
  const [triedOnce, setTriedOnce] = React.useState(false)

  const [statisticsDuty, setStatisticsDuty] = React.useState(0)
  const [solidarityLevy, setSolidarityLevy] = React.useState(null)
  const [ccvrLevy, setCcvrLevy] = React.useState(null)
  const [eximLevy, setEximLevy] = React.useState(null)
  const [townId, setTownId] = React.useState(null)

  const [town] = useTowns(townId)

  React.useEffect(refresh, [id])

  function refresh() {
    setTriedOnce(false)
    setLoading(true)
    if (id) {
      api.getFinalDestination(id)
        .then(({ data }) => {
          setStatisticsDuty(data.statisticDuty)
          setSolidarityLevy(data.solidarityLevy)
          setCcvrLevy(data.ccvrLevy)
          setEximLevy(data.eximLevy)
          setLoading(false)
          setTownId(data.town?.id)
        }).catch((e) => {
          console.error(e)
          notification.sendError('Something went wrong on fetching the data', e.message)
        })
    } else {
      setStatisticsDuty(0)
      setSolidarityLevy(null)
      setCcvrLevy(null)
      setEximLevy(null)
      setLoading(null)
      setTownId(null)
    }
  }

  function finish() {
    notification.sendSuccess('Destination saved successfully', town?.name)
    onHide()
    notify()
  }

  function send() {
    setTriedOnce(true)
    if (town && typeof statisticsDuty === 'number') {
      const payload = { statisticsDuty, solidarityLevy, ccvrLevy, eximLevy, town: town }
      setLoading(true)
      if (id) {
        api.editFinalDestination(id, payload)
          .then(finish).catch((e) => {
            console.error(e)
            notification.sendError('Something went wrong on saving the data', e.message)
          })
      } else {
        api.addFinalDestination(payload)
          .then(finish).catch((e) => {
            console.error(e)
            notification.sendError('Something went wrong on saving the data', e.message)
          })
      }
    }
  }

  function askDelete() {
    if (window.confirm(`Are you sure you want to delete ${town?.name}?\nThis is an one-way action!`)) {
      api.deleteFinalDestination(id)
        .then(finish).catch((e) => {
          console.error(e)
          notification.sendError('Something went wrong on saving the data', e.message)
        })
    }
  }

  const footer = <>
    { id && !loading && <Button label="Delete" icon="fa fa-trash-alt" className="p-button-danger" onClick={askDelete}/> }
    { !loading && <Button label="Save" icon="fa fa-save" onClick={send}/> }
    <Button label="Cancel" className="p-button-secondary" onClick={onHide}/>
  </>

  const body = <>
    <div style={{ marginTop: 8, marginBottom: 8 }}>
      <label htmlFor="town">Town:</label><br/>
      <TownSelector
        id="town"
        value={townId}
        onChange={setTownId}
        disableNull
      />
    </div>

    <FloatLabel id="statistics" label="Statistics Duty">
      <InputNumber
        value={statisticsDuty}
        suffix="%"
        mode="decimal"
        minFractionDigits={2}
        maxFractionDigits={3}
        onValueChange={(e) => setStatisticsDuty(e.value)}
      />
    </FloatLabel>

    <FloatLabel id="solidarity" label="Solidarity Levy">
      <InputNumber
        value={solidarityLevy}
        suffix="%"
        mode="decimal"
        minFractionDigits={2}
        maxFractionDigits={3}
        onValueChange={(e) => setSolidarityLevy(e.value)}
      />
    </FloatLabel>

    <FloatLabel id="ccvr" label="CCVR Levy">
      <InputNumber
        value={ccvrLevy}
        suffix="%"
        mode="decimal"
        minFractionDigits={2}
        maxFractionDigits={3}
        onValueChange={(e) => setCcvrLevy(e.value)}
      />
    </FloatLabel>

    <FloatLabel id="exim" label="Exim Levy">
      <InputNumber
        value={eximLevy}
        suffix="%"
        mode="decimal"
        minFractionDigits={2}
        maxFractionDigits={3}
        onValueChange={(e) => setEximLevy(e.value)}
      />
    </FloatLabel>
  </>

  return (
    <Dialog
      onHide={onHide}
      visible={visible}
      header={id ? `Editing ${town?.name}` : 'Creating'}
      footer={footer}
    >
      { loading ? <ProgressSpinner className={CommonStyles.CenterSpinner}/> : body }
    </Dialog>
  )
}

export default function FinalDestinationMaintenance() {

  const api = React.useContext(ApiContext)

  const dialog = ({ id, onHide, visible, notify }) => (
    <EditDialog id={id} onHide={onHide} visible={visible} notify={notify} />
  )

  return (
    <GenericMaintenanceScreen
      dataProvider={api.getFinalDestinations}
      renderEditDialog={dialog}
    >
      <Column field="id" header="Id" sortable/>
      <Column field="statisticDuty" header="Statistic Duty" sortable/>
      <Column field="solidarityLevy" header="Solidarity Levy" sortable/>
      <Column field="ccvrLevy" header="CCVR Levy" sortable/>
      <Column field="eximLevy" header="Exim Levy" sortable/>
      <Column
        field="town.name"
        header="Town"
        sortable
      />
      <Column
        field="town.country.displayName"
        header="Country"
        body={({ town }) => `${town.country.displayName} (${town.country.alphaCode})`}
        sortable
      />
    </GenericMaintenanceScreen>
  )
}
