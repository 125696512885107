import React from 'react';
import { ApiContext } from '../../../../providers/ApiProvider';
import { NotificationContext } from '../../../../providers/NotificationProvider';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';

import CommonStyles from '../../../CommonStyles.module.scss';
import { Dialog } from 'primereact/dialog';
import GenericMaintenanceScreen from './GenericMaintanceScreen';
import FloatLabel from '../../../utils/FloatLabel';
import { Checkbox } from 'primereact/checkbox';
import { TabPanel, TabView } from 'primereact/tabview';

function EditDialog({ id, onHide, visible, notify }) {
  const api = React.useContext(ApiContext)
  const notification = React.useContext(NotificationContext)

  const [loading, setLoading] = React.useState(false)
  const [triedOnce, setTriedOnce] = React.useState(false)

  const [name, setName] = React.useState('')
  const [minFob, setMinFob] = React.useState(0)
  const [maxFob, setMaxFob] = React.useState(0)
  const [hs6Code, setHs6Code] = React.useState(0)
  const [customTarrif, setCustomTarrif] = React.useState(0)

  const [isFiller, setIsFiller] = React.useState(false)
  const [N, setN] = React.useState(0)
  const [P, setP] = React.useState(0)
  const [K, setK] = React.useState(0)
  const [Ca, setCa] = React.useState(0)
  const [Mg, setMg] = React.useState(0)
  const [S, setS] = React.useState(0)
  const [Zn, setZn] = React.useState(0)
  const [B, setB] = React.useState(0)
  const [Cu, setCu] = React.useState(0)
  const [Cl, setCl] = React.useState(0)

  React.useEffect(refresh, [id])

  function refresh() {
    setTriedOnce(false)
    setLoading(true)
    if (id) {
      api.getProductType(id)
        .then(({ data }) => {
          setName(data.name)
          setMinFob(data.minFob)
          setMaxFob(data.maxFob)
          setHs6Code(data.hs6Code)
          setCustomTarrif(data.customTarrif)

          setIsFiller(data.isFiller)
          setN(data.N)
          setP(data.P2O5)
          setK(data.K2O)
          setCa(data.Ca)
          setMg(data.Mg)
          setS(data.S)
          setZn(data.Zn)
          setB(data.B)
          setCu(data.Cu)
          setCl(data.Cl)

          setLoading(false)
        }).catch((e) => {
          console.error(e)
          notification.sendError('Something went wrong on fetching the data', e.message)
        })
    } else {
      setName('')
      setMinFob(0)
      setMaxFob(0)
      setHs6Code(0)
      setCustomTarrif(0)
      setIsFiller(false)
      setN(0)
      setP(0)
      setK(0)
      setCa(0)
      setMg(0)
      setS(0)
      setZn(0)
      setB(0)
      setCu(0)
      setCl(0)
      setLoading(false)
    }
  }

  function finish() {
    notification.sendSuccess('Product Type saved successfully', name)
    onHide()
    notify()
  }

  function send() {
    setTriedOnce(true)

    function isNumber(it) {
      return typeof it === 'number'
    }

    if (name && [minFob, maxFob, hs6Code, customTarrif].every(isNumber)) {
      const payload = {
        name, minFob, maxFob, hs6Code, customTarrif, isFiller,
        N, Ca, Mg, S, Zn, B, Cu, Cl,
        P2O5: P,
        K2O: K
      }
      setLoading(true)
      if (id) {
        api.editProductType(id, payload)
          .then(finish).catch((e) => {
            console.error(e)
            notification.sendError('Something went wrong on saving the data', e.message)
          })
      } else {
        api.addProductType(payload)
          .then(finish).catch((e) => {
            console.error(e)
            notification.sendError('Something went wrong on saving the data', e.message)
          })
      }
    }
  }

  function askDelete() {
    if (window.confirm(`Are you sure you want to delete ${name}?\nThis is an one-way action!`)) {
      api.deleteProductType(id)
        .then(finish).catch((e) => {
          console.error(e)
          notification.sendError('Something went wrong on saving the data', e.message)
        })
    }
  }

  const footer = <>
    { id && !loading && <Button label="Delete" icon="fa fa-trash-alt" className="p-button-danger" onClick={askDelete}/> }
    { !loading && <Button label="Save" icon="fa fa-save" onClick={send}/> }
    <Button label="Cancel" className="p-button-secondary" onClick={onHide}/>
  </>

  const body = <>
    <TabView>
      <TabPanel header="Details">
        <FloatLabel id="name" label="Name">
          <InputText value={name} onChange={({ target }) => setName(target.value)}/>
        </FloatLabel>
        { triedOnce && !name && <span className={CommonStyles.RedText}>Please provide a name</span> }

        <FloatLabel id="minFob" label="Min FOB Price">
          <InputNumber
            value={minFob}
            mode="currency"
            currency="USD"
            onChange={({ value }) => setMinFob(value)}
          />
        </FloatLabel>

        <FloatLabel id="maxFob" label="Max FOB Price">
          <InputNumber
            value={maxFob}
            mode="currency"
            currency="USD"
            onChange={({ value }) => setMaxFob(value)}
          />
        </FloatLabel>

        <FloatLabel id="hs6Code" label="HS6 Code">
          <InputNumber
            value={hs6Code}
            useGrouping={false}
            min={0}
            onChange={({ value }) => setHs6Code(value)}
          />
        </FloatLabel>

        <FloatLabel id="customTarrif" label="Custom Tarrif">
          <InputNumber
            value={customTarrif}
            suffix="%"
            min={-1}
            onChange={({ value }) => setCustomTarrif(value)}
          />
        </FloatLabel>
      </TabPanel>
      <TabPanel header="Nutrients">
        <div className="p-grid">
          <div className="p-col-12 p-field">
            <label>
              <Checkbox
                checked={isFiller}
                onChange={e => setIsFiller(e.checked)}
                style={{ marginRight: 4 }}
              />
              Is Filler
            </label>
          </div>
          <div className="p-col-12" style={{ textAlign: 'center' }}>
            {
              [
                ["N", N, setN],
                ["P", P, setP],
                ["K", K, setK],
                ["Ca", Ca, setCa],
                ["Mg", Mg, setMg],
                ["S", S, setS],
                ["Zn", Zn, setZn],
                ["B", B, setB],
                ["Cu", Cu, setCu],
                ["Cl", Cl, setCl]
              ].map(([label, value, setValue]) => (
                <div className="p-inputgroup" style={{ height: 16, marginBottom: 2, width: '100%' }}>
                  <label htmlFor={label} className="p-inputgroup-addon" style={{ width: '3rem', borderRadius: 0 }}>{label}</label>
                  <InputNumber
                    inputId={label}
                    value={value}
                    mode="decimal"
                    maxFractionDigits={2}
                    minFractionDigits={2}
                    min={0}
                    onValueChange={e => setValue(e.value)}
                    style={{ width: '100%', height: 16 }}
                    inputStyle={{ borderRadius: 0, width: '100%' }}
                  />
                </div>
              ))
            }
          </div>

        </div>
      </TabPanel>
    </TabView>
  </>

  return (
    <Dialog
      onHide={onHide}
      visible={visible}
      header={id ? `Editing ${name}` : 'Creating'}
      footer={footer}
    >
      { loading ? <ProgressSpinner className={CommonStyles.CenterSpinner}/> : body }
    </Dialog>
  )
}

export default function ProductTypeMaintenance() {

  const api = React.useContext(ApiContext)

  const dialog = ({ id, onHide, visible, notify }) => (
    <EditDialog id={id} onHide={onHide} visible={visible} notify={notify} />
  )

  return (
    <GenericMaintenanceScreen
      dataProvider={api.getProductTypes}
      renderEditDialog={dialog}
    >
      <Column field="id" header="Id" sortable/>
      <Column field="name" header="Name" sortable/>
      <Column field="minFob" header="Min Fob" sortable/>
      <Column field="maxFob" header="Max Fob" sortable/>
      <Column field="hs6Code" header="HS6 Code" sortable/>
      <Column field="customTarrif" header="Custom Tarrif" sortable/>
    </GenericMaintenanceScreen>
  )
}
