import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { firestore } from '../../../services/firebase';
import { useSelector } from 'react-redux';
import { useErrorHandler } from '../../../hooks';
import copyToClipboard from 'copy-to-clipboard';
import { InputText } from 'primereact/inputtext';
import { NotificationContext } from '../../../providers/NotificationProvider';

export default function ShareDialog({ visible, setVisible }) {

  const errorHandler = useErrorHandler('Could not share your comparison')
  const notification = React.useContext(NotificationContext)

  const [shareId, setShareId] = React.useState()
  const formulas = useSelector(state => state.compare.formulas)

  function handleShare() {
    firestore.collection("compareShares")
      .add({ formulas })
      .then(ref => setShareId(ref.id))
      .catch(errorHandler)
  }

  function renderConfirm() {
    return (
      <div style={{ textAlign: 'center' }}>
        <Button
          className="p-button-success"
          icon="pi pi-check"
          label="Yes"
          onClick={handleShare}
          style={{ marginRight: 2 }}
        />
        <Button
          className="p-button-secondary"
          label="No"
          icon="pi pi-times"
          onClick={hide}
          style={{ marginLeft: 2 }}
        />
      </div>
    )
  }

  function renderCopy() {

    let search = window.location.search.replace('?', '')
    if (search) {
      search = '&' + search
    }

    const url = `${window.location.origin}/my/formulas/compare/shared?id=${shareId}${search}`

    function copy() {
      notification.sendInfo('Copied to clipboard')
      copyToClipboard(url)
    }

    return (
      <>
        <label style={{ fontWeight: 'bold' }} onClick={copy}>Copy to Clipboard:</label>
        <div className="p-inputgroup">
          <InputText contentEditable={false} value={url} style={{ fontSize: '.75rem' }}/>
          <span className="p-inputgroup-addon" title="Copy to Clipboard" style={{ cursor: 'pointer' }} onClick={copy}>
          <i className="fa fa-copy"/>
        </span>
        </div>
      </>
    )
  }

  function hide() {
    setVisible(false)
    setShareId(undefined)
  }

  return (
    <Dialog
      header={shareId ? "Comparison ready to be shared!" : "Do you want to share this comparison?"}
      onHide={hide}
      visible={visible}
      modal
    >
      { shareId ? renderCopy() : renderConfirm() }
    </Dialog>
  )
}
