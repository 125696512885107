import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import useProductTypes from '../../../../hooks/useProductTypes';
import Styles from './index.module.scss';
import { EditProductForm } from './EditProductForm';
import * as Actions from '../../../../redux/actions';
import { Carousel } from 'primereact/carousel';
import { areProductsEquals } from '../../../../utils';
import { Button } from 'primereact/button';

export default function EditProductDialog({ onSave, customFooter }) {
  const index = useSelector(state => state.ui.editingProduct)
  const products = useSelector(state => state.formula.products)
  const product = useSelector(state => state.formula.products[index])
  const formulaName = useSelector(state => state.formula.name)
  const productType = useProductTypes(product?.type)
  const dispatch = useDispatch()

  const [bufferedData, setBufferedData] = React.useState(products)

  const visible = index !== null

  React.useEffect(() => {
    if (visible) {
      setBufferedData(products)
    }
  }, [visible])


  function header() {
    return (
      <div>
        <span className={Styles.Title}>Editing {productType?.name}</span>
        <span className={Styles.SubTitle}>On {formulaName} at position {index+1}</span>
      </div>
    )
  }

  function footer() {
    function buttons() {
      return (
        <div className={Styles.Buttons}>
          <Button
            label="Keep"
            icon="fa fa-save"
            onClick={handleSave}
          />
        </div>
      )
    }

    return customFooter ? customFooter(buttons()) : buttons()
  }

  function handleSave() {
    bufferedData.forEach((data, index) => dispatch(Actions.updateProduct(index, data)))
    dispatch(Actions.uiStopEditProduct())
    onSave && onSave(bufferedData)
  }

  function handleHide() {
    if (visible) {
      const notChanged = products.every((data, index) => areProductsEquals(data, bufferedData[index], ['type', 'percentage']))
      if (notChanged || window.confirm("Discard changes?")) {
        dispatch(Actions.uiStopEditProduct())
      }
    }
  }

  function itemTemplate(data) {
    function mutateBufferedData(newData) {
      const newArray = [...bufferedData]
      newArray[data.index] = { ...bufferedData[index], ...newData }
      setBufferedData(newArray)
    }

    return <EditProductForm data={bufferedData[data.index]} onChange={mutateBufferedData} />
  }

  return (
    <Dialog
      header={header()}
      footer={footer()}
      visible={visible}
      onHide={handleHide}
      contentStyle={{ borderTop: `solid 4px ${product?.color}`, overflow: 'hidden' }}
      closeable
    >
      <Carousel
        onPageChange={e => visible && dispatch(Actions.uiEditProduct(e.page))}
        value={bufferedData.map((data, index) => ({ ...data, index }))}
        numVisible={1}
        numScroll={1}
        page={index}
        style={{ width: '80vw' }}
        itemTemplate={itemTemplate}
      />
    </Dialog>
  )


}
