export default (loader) => {
  let data;
  
  return {
    async get(...args) {
      if (data === undefined) {
        data = await loader(...args);
      }
      return data;
    },
    reset() {
      data = undefined;
    }
  }
}