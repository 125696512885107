import React from 'react';
import { ApiContext } from '../../../../providers/ApiProvider';
import { NotificationContext } from '../../../../providers/NotificationProvider';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';

import CommonStyles from '../../../CommonStyles.module.scss';
import { Dialog } from 'primereact/dialog';
import GenericMaintenanceScreen from './GenericMaintanceScreen';
import FloatLabel from '../../../utils/FloatLabel';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import PortSelector from '../../../utils/selectors/PortSelector';
import PassageMode from '../../../utils/selectors/PassageModeSelector';
import { InputNumber } from 'primereact/inputnumber';

function EditDialog({ id, onHide, visible, notify }) {
  const api = React.useContext(ApiContext)
  const notification = React.useContext(NotificationContext)
  const errorHandler = useErrorHandler('Something went wrong on managing the data')

  const [loading, setLoading] = React.useState(false)

  const [port, setPort] = React.useState()
  const [mode, setMode] = React.useState()
  const [min, setMin] = React.useState(0)
  const [max, setMax] = React.useState(0)

  React.useEffect(refresh, [id])

  function refresh() {
    setLoading(true)
    if (id) {
      api.getPortCharge(id)
        .then(({ data }) => {
          setPort(data.port.id)
          setMode(data.mode.id)
          setMin(data.min)
          setMax(data.max)
          setLoading(false)
        }).catch(errorHandler)
    } else {
      setPort(undefined)
      setMode(undefined)
      setMin(0)
      setMax(0)
      setLoading(false)
    }
  }

  function finish() {
    notification.sendSuccess('Charge saved successfully')
    onHide()
    notify()
  }

  function send() {
    if (port && mode) {
      const payload = { port, mode, min, max }
      setLoading(true)
      if (id) {
        api.editPortCharge(id, payload).then(finish).catch(errorHandler)
      } else {
        api.addPortCharge(payload).then(finish).catch(errorHandler)
      }
    }
  }

  function askDelete() {
    if (window.confirm(`Are you sure you want to delete?\nThis is an one-way action!`)) {
      api.deletePortCharge(id).then(finish).catch(useErrorHandler)
    }
  }

  const footer = <>
    { id && !loading && <Button label="Delete" icon="fa fa-trash-alt" className="p-button-danger" onClick={askDelete}/> }
    { !loading && <Button label="Save" icon="fa fa-save" onClick={send}/> }
    <Button label="Cancel" className="p-button-secondary" onClick={onHide}/>
  </>

  const body = <>
    <div className="p-grid">
      <div className="p-col">
        <label htmlFor="port">Port:</label><br/>
        <PortSelector id="port" value={port} onChange={setPort}/>
      </div>
      <div className="p-col">
        <label htmlFor="mode">Mode:</label><br/>
        <PassageMode id="mode" value={mode} onChange={setMode}/>
      </div>
    </div>
    <div className="p-grid">
      <div className="p-col">
        <FloatLabel id="min" label="Min Price">
          <InputNumber
            mode="currency"
            currency="USD"
            value={min}
            onValueChange={e => setMin(e.value)}
          />
        </FloatLabel>
      </div>
      <div className="p-col">
        <FloatLabel id="max" label="Max Price">
          <InputNumber
            mode="currency"
            currency="USD"
            value={max}
            onValueChange={e => setMax(e.value)}
          />
        </FloatLabel>
      </div>
    </div>
  </>

  return (
    <Dialog
      onHide={onHide}
      visible={visible}
      header={id ? 'Editing' : 'Creating'}
      footer={footer}
    >
      { loading ? <ProgressSpinner className={CommonStyles.CenterSpinner}/> : body }
    </Dialog>
  )
}

export default function PortChargesMaintenance() {

  const api = React.useContext(ApiContext)

  const dialog = ({ id, onHide, visible, notify }) => (
    <EditDialog id={id} onHide={onHide} visible={visible} notify={notify} />
  )

  return (
    <GenericMaintenanceScreen
      dataProvider={api.getPortCharges}
      renderEditDialog={dialog}
    >
      <Column field="id" header="Id" sortable/>
      <Column field="min" header="Min Price" sortable/>
      <Column field="max" header="Max Price" sortable/>
      <Column
        field="mode"
        header="Passage Mode"
        body={it => it?.mode?.name}
        sortable
      />
      <Column
        field="port"
        header="Port"
        body={({ port }) => `${port?.name} (${port?.country?.alphaCode})`}
        sortable
      />
    </GenericMaintenanceScreen>
  )
}
