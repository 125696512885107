import React from 'react';

import { FormulaInputForm } from './FormulaInputForm';
import { RatePanel } from './RatePanel';
import { MaterialPanel } from './MaterialPanel';

import Styles from './index.module.scss';

export function ForumulaCalculator() {

  const [formulaInput, setFormulaInput] = React.useState({})
  const [rate, setRate] = React.useState({})

  const [actualFormula, setActualFormula] = React.useState(Object.fromEntries([
    'n', 'p2O5', 'k20', 'mgO', 'fe', 'mn', 'zn', 'b', 'cu', 'ca', 's',
  ].map((key) => [key, 0])))

  return (
    <section className={Styles.FormulaCalculator}>
      <aside className={Styles.FormLeft}>
        <FormulaInputForm onChange={setFormulaInput}/>
      </aside>
      <section className={Styles.Right}>
        <RatePanel
          mode={formulaInput.formulaOption}
          actualFormula={actualFormula}
          onChange={setRate}
        />
        <MaterialPanel
          kgInput={{}}
        />
      </section>
    </section>
  )
}
